import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, User, updateProfile } from 'firebase/auth'
import { auth, db } from '../index'
import Modal from '../components/modal';
import { collection, doc, getDocs, query, setDoc } from 'firebase/firestore';
import { options } from '../App';
import { Icon } from '../components/icon';
import { BsGoogle } from 'react-icons/bs';


const plainStyle = 'shadow font-Poppins appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
const errorStyle = 'shadow font-Poppins appearance-none rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-2 border-red-500 outline-red-500'


export function Login(props: { onPageChange: (newPage: options) => void }) {
    const [user, setUser] = useState<User | null>(null);
    const [signedUp, setSignedUp] = useState(true)
    const GoogleIconStyle = ['h-4', 'w-4', 'text-blue-500', 'group-hover:text-blue-700', 'self-center', 'mx-2'].join(' ')
    const [loginStyle, setLoginStyle] = useState(plainStyle)
    const [signupStyle, setSignupStyle] = useState(plainStyle)
    const [loginError, setLoginError] = useState(false)
    const [signupError, setSignupError] = useState(false)

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        if (user) {
            props.onPageChange(options.bulletin)
        }
    }, [user])

    const handleCreateUser = async (username: string, email: string, password: string) => {
        try {
            const { user } = await createUserWithEmailAndPassword(auth, email, password);
            setUser(user);

            const userId = user.uid;
            const defaultProfilePicture = 'https://firebasestorage.googleapis.com/v0/b/prueba-ai-6c71c.appspot.com/o/utils%2FDefault_pfp.jpg?alt=media&token=486f1085-1b43-4853-b5e3-f901b99e9273'

            // Create a new document in the "users" collection with the user id
            const userRef = await setDoc(doc(db, 'users', userId),
                {
                    username: username,
                    email: email,
                    tokens: {
                        purchased: 500,
                        transferred: 0,
                    },
                    postIDs: [],
                    chats: [],
                    profilePicture: defaultProfilePicture,
                    imageCollection: []
                });
        } catch (error) {
            console.error(error);
            setSignupError(true)
            setSignupStyle(errorStyle)
        }

        if (auth.currentUser !== null) {
            updateProfile(auth.currentUser, {
                displayName: username
            }).then(() => {
                // Profile updated!
                // ...
            }).catch((error) => {
                // An error occurred
                // ...
            });
        }

    };

    const handleLoginWithEmailAndPassword = async (email: string, password: string) => {
        try {
            const { user } = await signInWithEmailAndPassword(auth, email, password);
            setUser(user);
        } catch (error) {
            console.error(error);
            setLoginError(true)
            setLoginStyle(errorStyle)
        }
    };

    const handleLoginWithGoogle = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const { user } = await signInWithPopup(auth, provider);
            setUser(user);

            const q = query(collection(db, "users"))
            const querySnapshot = await getDocs(q);

            let userExisted: boolean = false;

            querySnapshot.forEach((doc) => {
                if (doc.id === user.uid) {
                    userExisted = true
                }
            })

            if (!userExisted) {
                await setDoc(doc(db, 'users', user.uid),
                    {
                        username: user.displayName,
                        email: user.email,
                        tokens: {
                            purchased: 500,
                            transferred: 0,
                        },
                        postIDs: [],
                        chats: [],
                        profilePicture: user.photoURL,
                        imageCollection: []
                    });
            }
        } catch (error) {
            console.error(error);
            setLoginError(true)
            setSignupError(true)
            setLoginStyle(errorStyle)
            setSignupStyle(errorStyle)
        }
    };

    function toggleSignedUp() {
        if (signedUp) {
            setSignedUp(false)
        } else {
            setSignedUp(true)
        }
    }



    return (

        <div className="w-full h-full flex flex-col items-center justify-center py-10">
            {user ? (
                <>
                    <h1 className='font-Poppins'>LogIn Succesful!</h1>
                </>
            ) : (
                <>
                    {signedUp ? (
                        <div>
                            <h1 className="text-3xl font-bold mb-4">Iniciar Sesión</h1>
                            <form onSubmit={event => {
                                event.preventDefault();
                                const [email, password] = Array.from(event.currentTarget.elements) as HTMLInputElement[];
                                handleLoginWithEmailAndPassword(email.value, password.value);
                            }} className="w-full max-w-xs">
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2 font-Poppins" htmlFor="email">
                                        Email:
                                    </label>
                                    <input className={loginStyle} id="email" type="email" />
                                </div>
                                <div className="mb-6">
                                    <label className="block text-gray-700 text-sm font-bold mb-2 font-Poppins" htmlFor="password">
                                        Password:
                                    </label>
                                    <input className={loginStyle} id="password" type="password" />
                                </div>
                                {loginError ? (
                                    <div className='bg-red-200 py-2 px-4 rounded-lg my-4'>
                                        <p className=' font-Poppins text-sm text-red-700 self-center text-center'>Email o contraseña incorrecta.</p>
                                    </div>
                                ) : (null)}
                                <div className="flex items-center justify-between">
                                    <button type="submit" className="bg-blue-500 font-Poppins hover:bg-blue-700 w-full text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline">
                                        Iniciar Sesión
                                    </button>
                                </div>
                            </form>
                            <button onClick={handleLoginWithGoogle} className="bg-gray-100 border-2 w-full group justify-center border-blue-500 hover:border-blue-700 font-Poppins flex flex-row text-blue-500 hover:text-blue-700 font-bold py-2 px-4 rounded-xl mt-4">
                                <BsGoogle className={GoogleIconStyle} />
                                Seguir con Google</button>
                            <div onClick={toggleSignedUp} className='hover:bg-gray-200 py-2 px-4 cursor-pointer rounded-lg my-4'>
                                <p className=' font-Poppins text-sm text-gray-600 self-center text-center'>¿No tienes una cuenta? ¡Créala!</p>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h1 className="text-3xl font-bold mt-8 mb-4">Crear Usuario</h1>
                            <form onSubmit={event => {
                                event.preventDefault();
                                const [username, email, password] = Array.from(event.currentTarget.elements) as HTMLInputElement[];
                                handleCreateUser(username.value, email.value, password.value);
                            }} className="w-full max-w-xs">
                                <div className="mb-4">
                                    <label className="block font-Poppins text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                        Username:
                                    </label>
                                    <input className={signupStyle} id="username" type="username" />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-Poppins text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                        Email:
                                    </label>
                                    <input className={signupStyle} id="email" type="email" />
                                </div>
                                <div className="mb-6">
                                    <label className="block font-Poppins text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                        Password:
                                    </label>
                                    <input className={signupStyle} id="password" type="password" />
                                </div>
                                {signupError ? (
                                    <div className='bg-red-200 py-2 px-4 rounded-lg my-4'>
                                        <p className=' font-Poppins text-sm text-red-700 self-center text-center'>Error al crear usuario. Asegúrese de que la contraseña tiene mínimo 6 caracteres</p>
                                    </div>
                                ) : (null)}
                                <div className="flex  items-center justify-between">
                                    <button type="submit" className="bg-blue-500 font-Poppins w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline">
                                        Crear Usuario
                                    </button>
                                </div>
                            </form>
                            <button onClick={handleLoginWithGoogle} className="bg-gray-100 border-2 w-full  group justify-center
                             border-blue-500 hover:border-blue-700 font-Poppins flex flex-row text-blue-500 hover:text-blue-700 font-bold py-2 px-4 rounded-xl mt-4">
                                <BsGoogle className={GoogleIconStyle} />
                                Seguir con Google</button>
                            <div onClick={toggleSignedUp} className='hover:bg-gray-200 py-2 px-4 cursor-pointer rounded-lg my-4'>
                                <p className=' font-Poppins text-sm text-gray-600 self-center text-center'>¿Tienes una cuenta? ¡Inicia Sesión!</p>
                            </div>
                        </div>
                    )}

                </>
            )}
        </div>

    );
}
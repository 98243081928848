import { Timestamp } from "firebase/firestore";
import { auth } from ".";

export function timestampToString(timestamp: Timestamp) {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(timestamp.seconds * 1000);

  // Hours part from the timestamp
  const hours = date.getHours();
  // Minutes part from the timestamp
  const minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  const formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

  // Get the day of the month (1-31) from the timestamp
  const day = date.getDate();
  // Get the month (1-12) from the timestamp
  const month = date.getMonth() + 1;
  // Get the full year (4-digit) from the timestamp
  const year = date.getFullYear();

  // Will display date in DD/MM/YYYY format
  const formattedDate = day + '/' + month + '/' + year;

  return formattedTime + ' ' + formattedDate;
}

export function timestampToStringHour(timestamp: Timestamp) {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(timestamp.seconds * 1000);

  // Hours part from the timestamp
  const hours = date.getHours();
  // Minutes part from the timestamp
  const minutes = "0" + date.getMinutes();

  // Will display time in 10:30:23 format
  const formattedTime = hours + ':' + minutes.substr(-2);

  return formattedTime;
}

export function isMoreThanADayAgo(timestamp: Timestamp): boolean {
  // Get the current date and time
  const now = new Date();

  // Get the date and time from the timestamp
  const timestampDate = timestamp.toDate();

  // Calculate the difference in milliseconds
  const diff = now.getTime() - timestampDate.getTime();

  // Calculate the difference in days
  const diffInDays = diff / (1000 * 60 * 60 * 24);

  // Return true if the difference is greater than 1 day
  return diffInDays > 1;
}



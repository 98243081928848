import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { db, auth } from '../index'
import { addDoc, collection, doc, getDoc, Timestamp, updateDoc } from "firebase/firestore";
import { CategorySelect } from './CategorySelect';
import FileUploadButton from './FileUploadButton';
import { LocationSelect } from './locationSelect';
import { Icon } from './icon';
import { HiPlus } from 'react-icons/hi'
import { MdModeEdit } from 'react-icons/md';

export function UpdateForm(props: { id: string }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [editLocation, setEditLocation] = useState(false)
  const [category, setCategory] = useState(0)
  const [editCategory, setEditCategory] = useState(false)
  const [imageRef, setImageRef] = useState('https://firebasestorage.googleapis.com/v0/b/prueba-ai-6c71c.appspot.com/o/utils%2Flogo.png?alt=media&token=09808ad2-95e3-43f4-b832-7f7aa7b0d140')
  const [imageCol, setImageCol] = useState<string[]>([])
  const [successfullyPublished, setSuccessfullyPublished] = useState(false)
  const [image1, setImage1] = useState(false)
  const [image2, setImage2] = useState(false)
  const [image3, setImage3] = useState(false)
  const [image4, setImage4] = useState(false)
  const [image5, setImage5] = useState(false)
  const [plus, setPlus] = useState(false)
  const path = useRef('')

  const user = auth.currentUser

  useEffect(() => {
    if (user) {
      path.current = 'users/' + user.uid + '/'
    }

    async function loadAnounce() {

      let docRef = doc(db, 'anounces', props.id)

      let docSnap = await getDoc(docRef)

      if (docSnap.get('plus') === undefined) {
        docRef = doc(db, 'anouncesPlus', props.id)

        docSnap = await getDoc(docRef)

        setPlus(true)
      }

      let auxDesc: string = docSnap.get('description')
      let auxDescArray: string[] = auxDesc.split('||')

      setTitle(docSnap.get('title'))
      setDescription(auxDescArray[1])
      setLocation(docSnap.get('location'))
      setCategory(docSnap.get('category'))
      setImageRef(docSnap.get('imageRef'))

      setImageCol(docSnap.get('imageCol'))

    }

    loadAnounce()
  }, [props.id])

  function handleCategory(category: number) {
    setCategory(category)
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let modifDesc = title + '||' + description

    let docRef;

    if (plus) {
      docRef = doc(db, 'anouncesPlus', props.id)
    } else {
      docRef = doc(db, 'anounces', props.id)
    }
    await updateDoc(docRef, {
      title,
      description: modifDesc,
      location,
      category,
      imageRef,
      
    })
      .then(() => {
        setTitle('');
        setDescription('');
        setLocation('');
        setCategory(0);
        setImageRef('')
        setSuccessfullyPublished(true)
      });

      try {
        await updateDoc(docRef, {
          imageCol: imageCol,
        })
      } catch {}
      
  };

  function handleImageRef(imageRef: string) {
    setImageCol([...imageCol, imageRef])
    setImageRef(imageRef)
  }
  function handleImageCol(imageRef: string) {
    setImageCol([...imageCol, imageRef])
    console.log(imageCol)
  }

  function handleLocation(location: string) {
    setLocation(location)
  }

  const frameStyle = ['hover:bg-gray-200', 'cursor-pointer', 'rounded-full'].join(' ')
  const deleteIconStyle = ['h-5', 'w-5', 'text-gray-500', 'hover::text-gray-800'].join(' ')

  return (
    <div className='py-20 sm:px-2 md:px-10 lg:px-30 xl:px-50 2xl:px-80'>
      <h1 className=' font-Poppins font-bold text-2xl self-center my-4'>Editar Anuncio</h1>
      {successfullyPublished ? (
        <div className="flex flex-row w-full mx-auto bg-white rounded-xl p-4 h-24 shadow-lg m-4">
          <h1 className=' font-Poppins text-lg font-bold self-center'>¡Anuncio publicado correctamente!</h1>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block font-bold text-gray-700 mb-1 font-Poppins"
            >
              Título
            </label>
            <input
              type="text"
              id="title"
              className="border border-gray-300 bg-gray-50 rounded-lg w-full py-2 px-3 font-Poppins"
              maxLength={50}
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block font-bold text-gray-700 mb-1 font-Poppins"
            >
              Descripción
            </label>
            <textarea
              id="description"
              rows={6}
              className="border border-gray-300 bg-gray-50 rounded-lg w-full py-2 px-3 font-Poppins"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="location"
              className="block font-bold text-gray-700 mb-1 font-Poppins"
            >
              Ubicación
            </label>
            {editLocation ? (
              <LocationSelect onHandleLocation={handleLocation} />
            ) : (
              <div className='flex flex-row justify-center'>
                <div className='flex flex-row space-x-2 rounded-lg border border-gray-400 px-2'>
                  <label
                    htmlFor="location"
                    className="block font-bold text-gray-700 font-Poppins self-center px-2"
                  >
                    {location}
                  </label>
                  <Icon onPress={() => { setEditLocation(true) }} style={frameStyle}>
                    <MdModeEdit className={deleteIconStyle} />
                  </Icon>
                </div>
              </div>
            )}
          </div>
          <div className='flex flex-col space-y-4 justify-center px-6 sm:px-0 sm:space-y-0 sm:flex-row sm:justify-around'>
            {editCategory ? (
              <CategorySelect onHandleCategory={handleCategory} form={true} />

            ) : (
              <div>
                <label
                  htmlFor="category"
                  className="block font-bold text-gray-700 mb-1 font-Poppins"
                >
                  Categoría
                </label>
                <div className='flex flex-row justify-center'>
                  <div className='flex flex-row space-x-2 rounded-lg border border-gray-400 px-2'>
                    <label
                      htmlFor="location"
                      className="block font-bold text-gray-700 font-Poppins self-center px-2"
                    >
                      {category}
                    </label>
                    <Icon onPress={() => { setEditCategory(true) }} style={frameStyle}>
                      <MdModeEdit className={deleteIconStyle} />
                    </Icon>
                  </div>
                </div>
              </div>
            )}
            <div className='flex flex-col space-y-2'>
              <label
                htmlFor="image"
                className="block font-bold text-gray-700 mb-1 font-Poppins"
              >
                Imágenes
              </label>
              <h3 className=' font-Poppins'>Próximamente...</h3>
              {/* <div className='flex flex-row space-x-2'>
                <FileUploadButton onHandleFileUpload={handleImageRef} path={path.current} first={true} />
                <div className='flex flex-col justify-end'>
                  <Icon onPress={() => { setImage1(true) }} style={'justify-center hover:bg-gray-200 rounded-full h-fit w-fit'}>
                    <HiPlus className='h-7 w-7 self-center text-gray-600 hover:rotate-90' />
                  </Icon>
                </div>
              </div> */}
              {image1 ? (
                <div className='flex flex-row space-x-2'>
                  <FileUploadButton onHandleFileUpload={handleImageCol} path={path.current} first={false} />
                  <div className='h-full flex flex-col justify-end'>
                    <Icon onPress={() => { setImage2(true) }} style={'justify-center hover:bg-gray-200 rounded-full h-fit w-fit'}>
                      <HiPlus className='h-7 w-7 self-center text-gray-600 hover:rotate-90' />
                    </Icon>
                  </div>
                </div>
              ) : (null)}
              {image2 ? (
                <div className='flex flex-row space-x-2'>
                  <FileUploadButton onHandleFileUpload={handleImageCol} path={path.current} first={false} />
                  <div className='h-full flex flex-col justify-end'>
                    <Icon onPress={() => { setImage3(true) }} style={'justify-center hover:bg-gray-200 rounded-full h-fit w-fit'}>
                      <HiPlus className='h-7 w-7 self-center text-gray-600 hover:rotate-90' />
                    </Icon>
                  </div>
                </div>
              ) : (null)}
              {image3 ? (
                <div className='flex flex-row space-x-2'>
                  <FileUploadButton onHandleFileUpload={handleImageCol} path={path.current} first={false} />
                  <div className='h-full flex flex-col justify-end'>
                    <Icon onPress={() => { setImage4(true) }} style={'justify-center hover:bg-gray-200 rounded-full h-fit w-fit'}>
                      <HiPlus className='h-7 w-7 self-center text-gray-600 hover:rotate-90' />
                    </Icon>
                  </div>
                </div>
              ) : (null)}
              {image4 ? (
                <div className='flex flex-row space-x-2'>
                  <FileUploadButton onHandleFileUpload={handleImageCol} path={path.current} first={false} />
                  <div className='h-full flex flex-col justify-end'>
                    <Icon onPress={() => { setImage5(true) }} style={'justify-center hover:bg-gray-200 rounded-full h-fit w-fit'}>
                      <HiPlus className='h-7 w-7 self-center text-gray-600 hover:rotate-90' />
                    </Icon>
                  </div>
                </div>
              ) : (null)}
              {image5 ? (
                <div className='flex flex-row space-x-2'>
                  <FileUploadButton onHandleFileUpload={handleImageCol} path={path.current} first={false} />
                </div>
              ) : (null)}
            </div>
          </div>
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-16 my-4 rounded-lg font-Poppins">
            Publicar
          </button>
        </form>
      )}

    </div>
  );
};

import React, { useEffect } from 'react';


export function handleTimeSelectInverted (value:number | undefined) {

  let option;

  if (value !== undefined) {
    switch (value) {
      case 1:
        // Handle the "5 min" option
        option = "5 min"
        break;
      case 2:
        // Handle the "10 min" option
        option = "10 min"
        break;
      case 3:
        // Handle the "30 min" option
        option = "30 min"
        break;
      case 4:
        // Handle the "1 Hr" option
        option = "1 Hr"
        break;
      case 5:
        // Handle the "2 Hr" option
        option = "2 Hr"
        break;
      case 6:
        // Handle the "3 Hr" option
        option = "3 Hr"
        break;
      case 7:
        // Handle the "4 Hr" option
        option = "4 Hr"
        break;
      case 8:
        // Handle the "6 Hr" option
        option = "6 Hr"
        break;
      case 9:
        // Handle the "12 Hr" option
        option = "12 Hr"
        break;
      default:
        // Handle any other options not listed above
        option = '5 min'
        break;
    }
  }else {
    option = ''
  }


  return option;
}

export function TimeSelect (props:{onCategoryChange: (option:number) => void}) {

  useEffect(() => {
    props.onCategoryChange(1)
  }, [])


  function handleTimeSelect (event:React.ChangeEvent<HTMLSelectElement>) {

    const value = event.target.value

    let option;

    switch (value) {
      case "5":
        // Handle the "5 min" option
        option = 1
        break;
      case "10":
        // Handle the "10 min" option
        option = 2
        break;
      case "30":
        // Handle the "30 min" option
        option = 3
        break;
      case "60":
        // Handle the "1 Hr" option
        option = 4
        break;
      case "120":
        // Handle the "2 Hr" option
        option = 5
        break;
      case "180":
        // Handle the "3 Hr" option
        option = 6
        break;
      case "240":
        // Handle the "4 Hr" option
        option = 7
        break;
      case "360":
        // Handle the "6 Hr" option
        option = 8
        break;
      case "720":
        // Handle the "12 Hr" option
        option = 9
        break;
      default:
        // Handle any other options not listed above
        option = 1
        break;
    }

    
    props.onCategoryChange(option)

  }

  

  return (
      <select onChange={handleTimeSelect} className="block appearance-none w-full font-Poppins text-gray-700 font-semibold bg-white border-2 border-gray-200 hover:border-gray-400 px-4 py-2 pr-8 rounded-xl shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-gray-600 active:bg-gray-100 active:text-gray-700">
        <option value="5">5 min</option>
        <option value="10">10 min</option>
        <option value="30">30 min</option>
        <option value="60">1 Hr</option>
        <option value="120">2 Hr</option>
        <option value="180">3 Hr</option>
        <option value="240">4 Hr</option>
        <option value="360">6 Hr</option>
        <option value="720">12 Hr</option>
      </select>
  );
};

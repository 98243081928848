import React, { useEffect, useState } from 'react';
import './App.css';
import { AnounceForm } from './components/AnounceForm';
import { AnounceBulletin } from './components/AnounceBulletin';
import { Login } from './pages/login';
import { Chat } from './components/chat/chat';
import { Profile } from './pages/profile';
import { NavBar } from './components/navBar';
import { Route, Router, useParams } from 'react-router-dom';
import { Footter } from './components/Footter';
import { AnouncePage } from './pages/AnouncePage';
import { UpdateForm } from './components/UpdateForm';

export enum options {
  'bulletin',
  'form',
  'chat',
  'profile',
  'login',
  'anounce',
  'update',
}

function App() {

  const [option, setOption] = useState(options.bulletin)
  const [chatID, setChatID] = useState('noChatSelected');
  const [anounceID, setAnounceID] = useState('')
  const AddIconStyle = ['h-10', 'w-10', 'text-grey-500'].join(' ')

  // Function to change the current page
  const handlePageChange = (newPage: options) => {
    // Update the currentPage state with the new page value
    setOption(newPage);
  };

  const handleChatID = (id: string) => {
    setChatID(id)
  }

  const handleAnounceID = (id:string) => {
    setAnounceID(id)
    console.log('New ID: ' + id)
  }

  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const purchased = urlParams.get('p')
  const anounce = urlParams.get('a')
  useEffect(() => {
    if (purchased) {
      console.log(purchased)
      setOption(options.profile)
    } else if (anounce) {
      setAnounceID(anounce)
      setOption(options.anounce)
    }
  }, [])



  function switchPages(option: options): JSX.Element {
    switch (option) {
      case options.bulletin:
        return <AnounceBulletin onPageChange={handlePageChange} onHandleChatID={handleChatID} onAnounceID={handleAnounceID} profile={false} />
      case options.form:
        return <AnounceForm />
      case options.chat:
        return <Chat userToMessage={chatID} />
      case options.profile:
        return <Profile onPageChange={handlePageChange} onHandleChatID={handleChatID} onAnounceID={handleAnounceID}/>
      case options.login:
        return <Login onPageChange={handlePageChange} />
      case options.anounce:
        return <AnouncePage onHandleChatID={handleChatID} onPageChange={handlePageChange} id={anounceID} onAnounceID={handleAnounceID} />
        case options.update:
          return <UpdateForm id={anounceID}/>
      default:
        return <AnounceBulletin onPageChange={handlePageChange} onHandleChatID={handleChatID} onAnounceID={handleAnounceID} profile={false} />
    }
  }
  return (
      <div className="App flex flex-col bg-gray-100 shadow-md">

        <NavBar onPageChange={handlePageChange} />

        <div className='sm: px-2 md:px-10 lg:px-40'>
          {switchPages(option)}
        </div>

        <Footter/>
      </div>
  );
}

export default App;

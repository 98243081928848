import userEvent from '@testing-library/user-event';
import { addDoc, collection, deleteDoc, deleteField, doc, DocumentData, DocumentReference, getDoc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import React, { Fragment, useEffect, useState } from 'react';
import { options } from '../App';
import { auth, db, storage } from '../index'
import { timestampToString, isMoreThanADayAgo } from '../utils';
import { Icon } from './icon';
import { IoMdTrash } from 'react-icons/io'
import { deleteObject, ref, StorageReference } from 'firebase/storage';
import { Divider, dividerOptions } from './divider';
import { TimeSelect, handleTimeSelectInverted } from './timeSelect';
import { tokens } from '../pages/profile';
import { MdModeEdit } from 'react-icons/md';

interface anounce {
  id: string;
  title: string;
  description: string;
  location: string;
  createdAt: Timestamp;
  imageRef: string;
  plus: boolean;
  selectedTime?: number
};


export const AnounceCard = (props: { anounce: anounce, onPageChange: (newPage: options) => void, onHandleChatID: (id: string) => void, onAnounceID: (id: string) => void, postedBy: string, page:boolean }) => {

  const [wantToDelete, setWantToDelete] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [wantAutoRenovacion, setWantAutoRenovacion] = useState(false)
  const [wantDowngrade, setWantDowngrade] = useState(false)
  const [downgraded, setDowngraded] = useState(false)
  const [upgraded, setUpgraded] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [renewed, setRenewed] = useState(false)
  const [notEnoughTokens, setNotEnoughTokens] = useState(false)
  const [hstyle, sethStyle] = useState("flex flex-col-reverse sm:flex-row w-full mx-auto bg-white rounded-xl p-4 h-fit shadow-lg mb-4")

  let selectedTime: number;

  const user = auth.currentUser
  let isPostedByYou = false;
  let dayAgo = false

  if (user !== null) {
    if (props.postedBy === user.uid) {
      isPostedByYou = true

      if (isMoreThanADayAgo(props.anounce.createdAt)) {
        dayAgo = true
      }

    }
  }
  useEffect (() => {
    console.log('props.page: ' + props.page)
    if (props.page) {
      sethStyle('flex flex-col-reverse sm:flex-row w-full mx-auto bg-white rounded-xl p-4 shadow-lg mb-4')
    }
  }, [])

  function handleDeleteAnounce() {
    deleteAnounce()
  }
  async function deleteAnounce(data?: DocumentReference<DocumentData>) {


    let docRef;
    let imageRef: StorageReference;
    let aux: string;

    if (data !== undefined) {
      docRef = data
      let docSnap = getDoc(docRef)
      aux = (await docSnap).get('imageRef')
      imageRef = ref(storage, aux)
    } else {
      imageRef = ref(storage, props.anounce.imageRef);
      if (props.anounce.plus) {
        docRef = doc(db, 'anouncesPlus', props.anounce.id)
      } else {
        docRef = doc(db, 'anounces', props.anounce.id)
      }
    }

    deleteDoc(docRef).then(() => {
      
    })
  }

  function handleWantToDelete() {
    setWantToDelete(true)
  }
  function handleNotWantToDelete() {
    setWantToDelete(false)
  }
  function toggleWantToAR() {
    if (wantAutoRenovacion) {
      setWantAutoRenovacion(false)
    } else {
      setWantAutoRenovacion(true)
    }

  }
  function toggleWantToDowngrade() {

    if (wantDowngrade) {
      setWantDowngrade(false)
    } else {
      setWantDowngrade(true)
    }
  }

  async function upgradeAnounce() {
    const anounceRef = doc(db, 'anounces', props.anounce.id);
    const anouncePlusRef = doc(db, 'anouncesPlus', props.anounce.id);
    if (user) {
      const userRef = doc(db, 'users', user.uid)

      // get the data from the 'anounces' document
      const userData = await getDoc(userRef)
      const tokens: tokens = userData.get('tokens')
      if (tokens.purchased + tokens.transferred >= 10) {
        const anounceData = await getDoc(anounceRef)

        // add a new document to the 'anouncesPlus' collection with the same data

        await setDoc(anouncePlusRef, anounceData.data())

        updateDoc(anouncePlusRef, {
          plus: true,
          selectedTime,

        })

        updateDoc(userRef, {
          tokens: {
            purchased: tokens.purchased - 10,
            transferred: tokens.transferred
          }
        })
        // delete the 'anounces' document
        deleteAnounce(anounceRef)

        setUpgraded(true)
      } else {
        setNotEnoughTokens(true)
      }

    }



  }

  async function downgradeAnounce() {
    const anounceRef = doc(db, 'anounces', props.anounce.id);
    const anouncePlusRef = doc(db, 'anouncesPlus', props.anounce.id);

    await updateDoc(anouncePlusRef, {
      plus: false,
      selectedTime: deleteField(),

    })

    // get the data from the 'anounces' document
    const anouncePlusData = await getDoc(anouncePlusRef)

    // add a new document to the 'anouncesPlus' collection with the same data

    await setDoc(anounceRef, anouncePlusData.data())
    // delete the 'anounces' document
    deleteAnounce(anouncePlusRef)

    setDowngraded(true)


  }

  async function updateAnounce() {

    const anouncePlusRef = doc(db, 'anouncesPlus', props.anounce.id);

    await updateDoc(anouncePlusRef, {
      selectedTime,
    })

    setUpdated(true)

  }

  async function renewAnounce() {

    let docRef;

    if (props.anounce.plus) {
      docRef = doc(db, 'anouncesPlus', props.anounce.id)
    } else {
      docRef = doc(db, 'anounces', props.anounce.id)
    }

    await updateDoc(docRef, {
      createdAt: Timestamp.now(),
    })

    setRenewed(true)
  }

  function handleSelectedTime(option: number) {
    selectedTime = option
    console.log(selectedTime)
  }

  function handleEdit() {

  }


  const style = {
    backgroundImage: `url(${props.anounce.imageRef})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '1rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  };
  let ImageStyle = 'h-full w-full sm:h-full rounded-lg shadow-md bg-cover '
  const deleteIconStyle = ['h-5', 'w-5', 'text-gray-500', 'hover::text-gray-800'].join(' ')
  const frameStyle = ['hover:bg-gray-200', 'cursor-pointer', 'rounded-full'].join(' ')


  return (
    <div className='flex flex-col'>
      <div className='flex flex-row h-full'>
        {isDeleted ? (
          <div className="flex flex-row w-full mx-auto bg-white rounded-xl p-4 h-24 shadow-lg mb-4">
            <h1 className=' font-Poppins text-lg font-bold self-center'>Anuncio eliminado correctamente.</h1>
          </div>
        ) : (
          <div className={hstyle}>
            <div className='flex flex-col w-full sm:w-1/5 h-60 space-y-6'>
              <div className={ImageStyle} style={style}></div>
              {isPostedByYou ? (
                <button
                  type="submit"
                  className="bg-blue-300 text-white w-full font-bold py-2 px-4 rounded-lg self-center font-Poppins" disabled>
                  Chat
                </button>

              ) : (
                <div>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white w-full font-bold py-2 px-4 rounded-lg self-center font-Poppins"
                    onClick={() => {
                      if (user){
                        props.onPageChange(options.chat)
                        props.onHandleChatID(props.postedBy)
                      } else {
                        props.onPageChange(options.login)
                      }
                    }} >
                    Chat
                  </button>
                </div>
              )}

            </div>
            <div className='flex flex-col w-full sm:w-4/5 h-full px-2 sm:px-8 justify-between'>
              <div className='flex flex-row w-full max-w-full max-h-full overflow-hidden'>
                {props.page ? (
                  <h3 className=" font-Poppins font-bold mr-2 text-sm sm:text-xl self-center w-full flex flex-shrink break-words flex-wrap max-w-full max-h-full ">{props.anounce.title}</h3>
                ):(
                <h3 onClick={() => {
                  props.onAnounceID(props.anounce.id)
                  props.onPageChange(options.anounce)
                  }} className=" font-Poppins font-bold mr-2 text-sm sm:text-xl self-center w-full flex flex-shrink break-words flex-wrap max-w-full max-h-full cursor-pointer">{props.anounce.title}</h3>

                )}
                <p className="font-Poppins text-xs sm:text-md text-gray-700 self-center flex-shrink sm:flex-shrink-0">{props.anounce.location}</p>
                {isPostedByYou ? (
                  <div className='flex felx-row w-fit justify-end'>
                    <div>
                        <Icon onPress={() => {
                          props.onAnounceID(props.anounce.id)
                          props.onPageChange(options.update)
                        }} style={frameStyle}>
                          <MdModeEdit className={deleteIconStyle} />
                        </Icon>
                      </div>
                    {wantToDelete ? (
                      <div className='flex flex-row space-x-3'>
                        <p className='font-Poppins text-sm self-center'>Estas Seguro?</p>
                        <button className='h-8 w-8 p-1 flex justify-center bg-red-300 hover:bg-red-400 rounded-xl  font-Poppins  text-center font-bold text-red-700' onClick={handleDeleteAnounce}>Sí</button>
                        <button className='h-8 w-8 p-1 flex justify-center bg-gray-300 hover:bg-gray-400 text-gray-700 rounded-xl  font-Poppins text-center font-bold' onClick={handleNotWantToDelete}>No</button>
                      </div>
                    ) : (
                      <div>
                        <Icon onPress={handleWantToDelete} style={frameStyle}>
                          <IoMdTrash className={deleteIconStyle} />
                        </Icon>
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className='flex flex-row'>
                <p className="font-Poppins text-gray-700 mb-2 max-w-full max-h-full overflow-hidden">{props.anounce.description}</p>
              </div>
              <div className='flex flex-row justify-end'>
                {props.anounce.plus ? (
                  <div className='my-2'>
                    {isPostedByYou ? (
                      <div className='flex flex-col space-y-2'>
                        <p className=' font-Poppins font-bold text-gray-500 text-xs'>Renov. cada: {handleTimeSelectInverted(props.anounce.selectedTime)}</p>
                        <button onClick={toggleWantToDowngrade} className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-pink-500 hover:via-indigo-500 hover:to-purple-500 text-white font-bold py-2 px-4 rounded-xl font-Poppins hover:transition hover:duration-300 hover:ease-in-out">Auto-Renovado</button>
                      </div>
                    ) : (
                      <div>

                        <button className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-pink-500 hover:via-indigo-500 hover:to-purple-500 text-white font-bold py-2 px-4 rounded-xl font-Poppins hover:transition hover:duration-300 hover:ease-in-out" disabled>Auto-Renovado</button>

                      </div>
                    )}
                  </div>
                ) : (
                  <div className='w-fit flex-grow sm:flex-grow-0'>
                    {isPostedByYou ? (
                      <div className='flex flex-col w-full space-y-2'>
                        <div className='flex flex-row-reverse sm:flex-col sm:space-y-2 justify-between w-full'>
                          <div className='group bg-gradient-to-r h-fit from-indigo-500 via-purple-500 to-pink-500 hover:from-pink-500 hover:via-indigo-500 hover:to-purple-500 text-white font-bold p-[2px] rounded-xxl'>
                            <div className="bg-white text-white font-bold py-2 px-4 rounded-xxl font-Poppins hover:transition hover:duration-300 hover:ease-in-out" >
                              <h3 className='font-Poppins text-sm sm:text-base text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 group-hover:from-pink-500 group-hover:via-indigo-500 group-hover:to-purple-500 cursor-pointer' onClick={toggleWantToAR}>¿Auto-Renovar?</h3>
                            </div>
                          </div>
                          <div>
                            {dayAgo ? (
                              <div className='group bg-gradient-to-r from-teal-400  to-blue-500 hover:from-blue-500 hover:to-teal-400  text-white font-bold p-[2px] rounded-xxl'>
                                <div className="bg-white text-white font-bold py-2 px-4 rounded-xxl font-Poppins hover:transition hover:duration-300 hover:ease-in-out" >
                                  {renewed ? (
                                    <div>
                                      <h3 className='font-Poppins text-transparent bg-clip-text bg-gradient-to-r from-teal-400  to-blue-500 group-hover:from-blue-500 group-hover:to-teal-400  cursor-pointer'>¡Renovado!</h3>
                                    </div>
                                  ) : (
                                    <div>
                                      <h3 className='font-Poppins text-transparent text-sm sm:text-base bg-clip-text bg-gradient-to-r from-teal-400  to-blue-500 group-hover:from-blue-500 group-hover:to-teal-400  cursor-pointer' onClick={renewAnounce}>Renovar GRATIS</h3>
                                    </div>
                                  )}

                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                        <p className="font-Poppins text-sm text-gray-700 w-full self-center my-4 ">
                          {timestampToString(props.anounce.createdAt)}
                        </p>
                      </div>
                    ) : (
                      <p className="font-Poppins text-sm text-gray-700 w-full self-center my-4 ">
                        {timestampToString(props.anounce.createdAt)}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>

          </div >

        )}
      </div >
      {wantAutoRenovacion ? (
        <div className='flex flex-col justify-center'>
          <Divider option={dividerOptions.color} />
          <div className="flex flex-row flex-wrap justify-center 2xl:justify-evenly 2xl:flex-nowrap flex-grow w-full mx-auto bg-white rounded-xl p-4 h-fit shadow-lg mb-4">
            {upgraded ? (
              <div className='flex flex-col space-y-3'>
                <div className='flex flex-row'>
                  <div className="bg-white h-16 font-bold py-2 px-4 rounded-lg font-Poppins hover:transition hover:duration-300 hover:ease-in-out flex flex-col justify-center flex-shrink-0" >
                    <h3 className='font-Poppins text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 cursor-pointer'>¡Enhorabuena!</h3>
                  </div>
                  <div className='font-semibold text-gray-700 h-full py-2 px-4 rounded-lg font-Poppins flex flex-col justify-center sm:flex-shrink md:flex-shrink-0'>
                    <p className='font-Poppins text-gray-700'> Tu anuncio está ahora en modo </p>
                  </div>
                  <div className="bg-white h-16 font-bold py-2 px-4 rounded-lg font-Poppins hover:transition hover:duration-300 hover:ease-in-out flex flex-col justify-center flex-shrink-0" >
                    <h3 className='font-Poppins text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 cursor-pointer'>Auto-Renovación</h3>
                  </div>
                </div>
                <p className=' font-Poppins text-xs text-gray-500'>Actualiza la página para ver los resultados.</p>
              </div>
            ) : (
              <div>
                {notEnoughTokens ? (
                  <div className='flex flex-row'>
                    <div className='font-semibold text-gray-700 h-full py-2 px-4 rounded-lg font-Poppins flex flex-col justify-center space-y-3 sm:flex-shrink md:flex-shrink-0'>
                      <p className='font-Poppins text-gray-700'> Error. No tienes suficientes monedas para hacer eso. Debes tener al menos 10.</p>
                      <p className=' font-Poppins text-xs text-gray-500'>Puedes comprar más en tu perfil.</p>
                    </div>
                  </div>
                ) : (
                  <div className='flex flex-row flex-wrap space-y-5 sm:space-y-2 justify-center 2xl:justify-evenly 2xl:flex-nowrap flex-grow w-full mx-auto'>
                    <div className='flex flex-col sm:flex-row'>
                      <div className="bg-white h-16 font-bold py-2 px-4 rounded-lg font-Poppins hover:transition hover:duration-300 hover:ease-in-out flex flex-col justify-center flex-shrink-0" >
                        <h3 className='font-Poppins text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 cursor-pointer'>Auto-Renovación:</h3>
                      </div>
                      <div className='font-semibold text-gray-700 h-full py-2 px-4 rounded-lg font-Poppins flex flex-col justify-center sm:flex-shrink md:flex-shrink-0'>
                        <p className='font-Poppins text-gray-700'>Cada cuanto quieres que se auto-renueve?</p>
                      </div>
                    </div>
                    <div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0'>
                      <div className='flex flex-col sm:flex-row space-y-2'>
                        <div className='flex px-4'>
                          <TimeSelect onCategoryChange={handleSelectedTime} />
                        </div>
                        <div className='font-semibold text-gray-700 sm:h-full sm:py-2 sm:px-4 m-0 sm:mx-4 rounded-lg font-Poppins flex sm:flex-col justify-center flex-shrink-0'>
                          <p className='font-Poppins text-gray-700'>10 Token = 1 Renovación</p>
                        </div>
                      </div>
                      <div className='flex flex-row justify-center sm:justify-end'>
                        <button onClick={upgradeAnounce} className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-pink-500 hover:via-indigo-500 hover:to-purple-500 text-white font-bold py-2 px-4 rounded-xl font-Poppins hover:transition hover:duration-300 hover:ease-in-out">Confirmar</button>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {wantDowngrade ? (
        <div className='flex flex-col justify-center'>
          <Divider option={dividerOptions.color} />
          <div className="flex flex-row 2xs:flex-wrap sm:justify-center 2xl:justify-evenly 2xl:flex-nowrap flex-grow w-full mx-auto bg-white rounded-xl p-4 h-fit shadow-lg mb-4">
            {downgraded ? (
              <div className='flex flex-row'>
                <div className='font-semibold text-gray-700 h-full py-2 px-4 rounded-lg font-Poppins flex flex-col justify-center sm:flex-shrink md:flex-shrink-0'>
                  <p className='font-Poppins text-gray-700'> Tu anuncio ya no está en modo Auto-renovación.</p>
                </div>
              </div>

            ) : updated ? (
              <div className='flex flex-row'>
                <div className='font-semibold text-gray-700 h-full py-2 px-4 rounded-lg font-Poppins flex flex-col justify-center space-y-3 sm:flex-shrink md:flex-shrink-0'>
                  <p className='font-Poppins text-gray-700'> ¡Tu anuncio se ha actualizado correctamente!.</p>
                  <p className=' font-Poppins text-xs text-gray-500'>Actualiza la página para ver los resultados.</p>
                </div>
              </div>
            ) : (
              <div className='flex flex-row flex-wrap sm:justify-center 2xl:justify-evenly 2xl:flex-nowrap flex-grow w-full mx-auto'>
                <div className='flex flex-row'>
                  <div className="bg-white h-16 font-bold py-2 px-4 rounded-lg font-Poppins hover:transition hover:duration-300 hover:ease-in-out flex flex-col justify-center sm:flex-shrink md:flex-shrink-0" >
                    <h3 className='font-Poppins text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 cursor-pointer'>Auto-Renovación:</h3>
                  </div>
                  <div className='font-semibold text-gray-700 h-full py-2 px-4 rounded-lg font-Poppins flex flex-col justify-center sm:flex-shrink md:flex-shrink-0 xl:flex-shrink'>
                    <p className='font-Poppins text-gray-700'>Cada cuanto quieres que se auto-renueve?</p>
                  </div>
                </div>
                <div className='flex flex-row flex-wrap space-y-4 justify-center md:flex-nowrap md:space-y-0 md:justify-evenly '>
                  <div className='flex flex-row min-h-fit'>
                    <div className='flex px-4 min-w-fit'>
                      <TimeSelect onCategoryChange={handleSelectedTime} />
                    </div>
                    <div className='font-semibold text-gray-700 h-full py-2 px-4 mx-4 rounded-lg font-Poppins flex flex-col justify-center flex-shrink'>
                      <p className='font-Poppins text-gray-700'>10 Token = 1 Renovación</p>
                    </div>
                  </div>
                  <div className='flex flex-row justify-end space-x-2 min-h-fit'>
                    <button onClick={updateAnounce} className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-pink-500 hover:via-indigo-500 hover:to-purple-500 text-white font-bold py-2 px-4 rounded-xl font-Poppins hover:transition hover:duration-300 hover:ease-in-out">Confirmar</button>
                    <button onClick={downgradeAnounce} className=" bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded-xl font-Poppins">Cancelar Auto-renovación</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}

    </div>
  );
};

import React, { useEffect, useState } from 'react'
import { Header } from './header'
import * as firebase from 'firebase/app';
import 'firebase/database';
import { db, rtdb } from '../..';
import { onValue, ref, set } from 'firebase/database';
import { arrayUnion, doc, DocumentData, DocumentSnapshot, getDoc, onSnapshot, Timestamp, updateDoc } from 'firebase/firestore';
import { auth } from '../../index'

import { HiOutlineEmojiHappy, HiPaperAirplane } from 'react-icons/hi';
import { MessageRow } from './message';
import { chatData } from './chat';
import { Divider, dividerOptions } from '../divider';

export interface Message {
    sender_id: string;
    text: string;
    timestamp: Timestamp;
    status: string;
}

export function Messaging(props: { chatID: string, member: string | undefined }) {

    const aux: Message[] = []
    const [messages, setMessages] = useState(aux);
    const [currentMessage, setCurrentMessage] = React.useState('');
    const [currentMember, setCurrenmember] = useState(props.member)
    const docRef = doc(db, "chats", props.chatID);
    const user = auth.currentUser



    useEffect(() => {
        let loadMessages: Message[] = [];
        async function fetchData() {
            console.log('props.chatID: ' + props.chatID)
            if (props.chatID !== 'exception' && user) {
                const unsub = onSnapshot(docRef, (chat) => {

                    let members: string[] = chat.get('members')

                    members.forEach((member) => {
                        if (member !== user.uid) {
                            setCurrenmember(member)
                        }
                    })
                    loadMessages = chat.get('messages')
                    setMessages(loadMessages)
                    console.log(loadMessages)
                })
            }
        }

        fetchData()

    }, [props.chatID]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setCurrentMessage(event.target.value);
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const user = auth.currentUser

        if (user !== null) {
            let currentFinalMessage: Message = {
                sender_id: user.uid,
                text: currentMessage,
                timestamp: Timestamp.now(),
                status: 'Sent'
            }
            await updateDoc(docRef, {
                messages: arrayUnion(currentFinalMessage)
            })
            setCurrentMessage('');

            console.log('member: ' + props.member)

            if (props.member !== undefined && props.member !== 'noChatSelected') {
                const memberRef = doc(db, "users", props.member)

                const newChatData: chatData = {
                    id: props.chatID,
                    member: user.uid
                }

                await updateDoc(memberRef, {
                    chats: arrayUnion(newChatData)
                })
            }
        } else {
            alert('You must be Signed In to do that!')
        }



    }
    return (
        <div className="flex flex-col flex-grow flex-1 space-y-10 my-4 max-h-[80vh]">
            <div>
                <Header userToMesage={currentMember} />
                <Divider option={dividerOptions.plain} />
            </div>
            <div className="sm:px-16 py-4 overflow-y-scroll flex flex-col-reverse  scrollbar max-h-screen">
                <div>
                    {messages.length > 0 ? (
                        <ul>
                            {messages.map(message => (
                                <><div>
                                    <MessageRow message={message} />
                                </div></>
                            ))}
                        </ul>) : (
                        <div className='flex flex-col space-y-4'>
                            <div className='font-Poppins'>¡Wow! ¡Que vacío!</div>
                            <div className='font-Poppins'>Podrías empezar a rellenar el chat ;)</div>
                        </div>
                    )}
                </div>
            </div>
            <div className='bottom-0'>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-row sm:mx-16 my-2">
                        <div className="flex flex-row bg-gray-200 p-3 rounded-full flex-grow flex-shrink">
                            <input
                                className="bg-transparent focus:outline-none ml-4 flex-grow flex-shrink font-Poppins w-full max-w-full"
                                placeholder="Mensaje"
                                value={currentMessage}
                                onChange={handleChange}
                            ></input>
                            <HiOutlineEmojiHappy className="h-6 w-6 text-blue-600 mr-4" />
                        </div>
                        <button type='submit'><HiPaperAirplane className="text-blue-600 h-8 w-8 my-auto mx-4" /></button>
                    </div>
                </form>
            </div>

        </div>
    )
}

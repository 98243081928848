import * as React from 'react';
import { useEffect, useState } from 'react';

interface Category {
    [key: string]: {
        [key: string]: string | {
            [key: string]: string;
        }
    }
};


const categories: Category = {
    Citas: {
        Mujeres: 'Mujeres',
        Hombres: 'Hombres',
        Lesbianas: 'Lesbianas',
        Gay: 'Gay',
        Trans: 'Trans',
        Bi: 'Bi',
    },
    Sexo: {
        Mujeres: 'Mujeres',
        Hombres: 'Hombres',
        Lesbianas: 'Lesbianas',
        Gay: 'Gay',
        Trans: 'Trans',
        Bi: 'Bi',
        Parejas: 'Parejas',
        DomBDSM: 'Dom/BDSM/FemDom'
    },
    Acompñantes_y_masajistas: {

        Mujeres: 'Mujeres',
        Hombres: 'Hombres',
        Lesbianas: 'Lesbianas',
        Gay: 'Gay',
        Trans: 'Trans',
        Bi: 'Bi',

    },
};

export function CategorySelect(props: { onHandleCategory: (category: number) => void, form:boolean}) {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');

    const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCategory(event.target.value);
        console.log(event.target.value)
    };

    function handleSubcategoryChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedSubcategory(event.target.value);
    }

    useEffect(() => {
        let category = getCategoryNumber(selectedCategory, selectedSubcategory)
        props.onHandleCategory(category)
        setTimeout(() => {
            console.log(category)
        }, 1000);
    }, [selectedCategory, selectedSubcategory])


    function getCategoryNumber(category: string, subcategory: string) {

        let categoryToLoad: string = '0'

        switch (category) {
            case 'Citas': {
                categoryToLoad = '1'
                break;
            } case 'Sexo': {
                categoryToLoad = '2'
                break;
            } case 'Acompñantes_y_masajistas': {
                categoryToLoad = '3'
                break;
            }
        }

        switch (subcategory) {
            case 'Mujeres': {
                categoryToLoad = categoryToLoad + '1'
                break;
            } case 'Hombres': {
                categoryToLoad = categoryToLoad + '2'
                break;
            } case 'Lesbianas': {
                categoryToLoad = categoryToLoad + '3'
                break;
            } case 'Gay': {
                categoryToLoad = categoryToLoad + '4'
                break;
            } case 'Trans': {
                categoryToLoad = categoryToLoad + '5'
                break;
            } case 'Bi': {
                categoryToLoad = categoryToLoad + '6'
                break;
            } case 'Parejas': {
                categoryToLoad = categoryToLoad + '7'
                break;
            } case 'DomBDSM': {
                categoryToLoad = categoryToLoad + '8'
                break;
            }
        }

        return parseInt(categoryToLoad)
    }

    return (
        <div className='flex flex-col'>
            {props.form ? (
                <label
                htmlFor="category"
                className="block font-bold text-gray-700 mb-1 font-Poppins"
            >
                Categoría
            </label>
            ):(null)}
            <div className='flex flex-row space-x-3 self-center'>
                <div className="relative rounded-md flex flex-col justify-center">
                    <select
                        className="form-input py-2 px-3 block w-full font-Poppins font-semibold text-gray-700 leading-5 rounded-lg transition duration-150 ease-in-out bg-gray-100 border border-gray-300 appearance-none focus:outline-none focus:shadow-outline-blue focus:border-gray-500 sm:text-sm sm:leading-5"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        <option value="" disabled>
                            Categoría
                        </option>
                        {Object.keys(categories).map((category) => (
                            <option key={category} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                </div>
                {selectedCategory && (
                    <div className="">
                        <div className="relative rounded-md flex flex-col justify-center">
                            <select
                                className="form-input py-2 px-3 block w-full font-Poppins font-semibold text-gray-700 leading-5 rounded-lg transition duration-150 ease-in-out bg-gray-100 border border-gray-300 appearance-none focus:outline-none focus:shadow-outline-blue focus:border-gray-500 sm:text-sm sm:leading-5"
                                value={selectedSubcategory}
                                onChange={handleSubcategoryChange}
                            >
                                <option value="" disabled>
                                    Subcategoría
                                </option>
                                {Object.keys(categories[selectedCategory]).map((subcategory) => (
                                    <option key={subcategory} value={subcategory}>
                                        {subcategory}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                    className="fill-current h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

import React, { useState } from 'react'

export enum dividerOptions {
    'plain',
    'color',
} 

export function Divider(props: {option:dividerOptions}) {

    

    let style = 'h-[2px] w-5/6 rounded-lg self-center'
    
    
    switch (props.option) {
        case (dividerOptions.plain): {
            style = style + ' bg-gray-300 my-4'
            break
        }
        case (dividerOptions.color): {
            style = style + ' bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 mt-2 mb-6'
            break
        } default: {
            style = style + ' bg-gray-300 my-4'
            break
        }
    }
    
    return (
        <div className='flex flex-row w-full justify-center'>
            <div className={style}></div>
        </div>
    )
}
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import path from 'path';
import React, { useEffect, useState } from 'react';
import { storage } from '../index';

// helper function to convert a data URL to a Blob
function dataURItoBlob(dataURI: string) {
    // convert the data URL to a binary string
    const byteString = atob(dataURI.split(',')[1]);

    // create an array of 8-bit unsigned integers
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    // set the bytes of the array to the values from the binary string
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // create a Blob object containing the array buffer
    return new Blob([ab], { type: 'image/jpeg' });
}

export function FileUploadButton(props: { onHandleFileUpload: (fileURL: string) => void, path: string, first:boolean}) {
    const [file, setFile] = useState<File | null>(null);
    const [errorData, setErrorData] = useState('')
    const [failed, setFailed] = useState(false)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        console.log(selectedFile)
        if (selectedFile) {
            // check that the file is an image
            if (selectedFile.type.startsWith('image/')) {
                setFile(selectedFile);
            } else {
                // handle error
                console.error('File is not an image!');
            }
        }
    };

    useEffect(() => {

        function handleUpload() {
            if (file) {
                // create a new FileReader
                const reader = new FileReader();

                // read the file as an ArrayBuffer
                reader.readAsArrayBuffer(file);

                // listen for the 'load' event on the FileReader
                reader.addEventListener('load', () => {
                    // once the file has been read, create a new Blob containing the file data
                    const fileBlob = new Blob([reader.result as ArrayBuffer]);

                    // create an image element containing the file data
                    const image = new Image();
                    image.src = URL.createObjectURL(fileBlob);


                    // listen for the 'load' event on the image element
                    image.addEventListener('load', () => {

                        const { width: originalWidth, height: originalHeight } = image;

                        // create a canvas element to draw the resized image on
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        const ratio = originalWidth / originalHeight

                        console.log(ratio)
                        // set the dimensions of the canvas to the desired size for the resized image
                        if (ratio >= 1) {
                            canvas.width = 500;
                            canvas.height = 500 / ratio;
                        } else {
                            canvas.width = 500 / ratio;
                            canvas.height = 500;
                        }

                        // once the image has loaded, draw it to the canvas at the desired size
                        if (ctx) {
                            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                        }
                        // get the data for the resized image
                        const resizedImageData = canvas.toDataURL('image/jpeg');

                        // create a new Blob object containing the resized image data
                        const resizedImageBlob = dataURItoBlob(resizedImageData);

                        // upload the resized image to Firebase Storage using the existing code
                        const storageRef = ref(storage, props.path + file.name + '/' + file.name);
                        const uploadTask = uploadBytesResumable(storageRef, resizedImageBlob);

                        uploadTask.on('state_changed', (snapshot) => {
                            // monitor the progress of the upload
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                        }, (error) => {
                            // handle errors
                            setErrorData(error.message)
                            setFailed(true)
                            console.log(error)
                        }, () => {
                            // handle successful upload
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                props.onHandleFileUpload(downloadURL)
                            });
                        });
                    });
                });
            }
        };

        handleUpload()

    }, [file])

    return (
        <div className='flex flex-col'>
            {props.first? (
                <label
                htmlFor="image"
                className="block font-bold text-gray-700 mb-1 font-Poppins"
            >
                Imagen
            </label>
            ):(null)}
            <div className="py-2 px-4 bg-gray-100 rounded-lg border-2 border-dashed border-gray-400">
                <input id="file" type="file" onChange={handleFileChange} className=' block w-full text-sm text-slate-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-full file:border-0
      file:cursor-pointer
      file:text-sm file:font-semibold
      file:bg-gray-100 file:text-gray-500
      hover:file:bg-gray-200' />
            </div>
            {failed ? (
                <p className=' font-Poppins text-red-500 text-sm'>Ha ocurrido un error. Vuelva a intentarlo.</p>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default FileUploadButton;


import React, { useEffect, useState } from 'react'
import { SearchBar } from '../searchbar'
import user from '../../data/user.json'
import { doc, DocumentData, DocumentReference, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../..';

interface contact {
    id: string,
    member: string,
}

interface style {
    backgroundImage: string,
    backgroundSize: string,
    backgroundPosition: string,
    boxShadow: string

}

export function Contacts(props: { userUID: string | undefined, onHandleChatChange: (id:string) => void }) {

    const aux: contact[] = [];
    const [contacts, setContacts] = useState(aux)


    let userRef: DocumentReference<DocumentData>;
    if (props.userUID !== undefined) {
        userRef = doc(db, 'users', props.userUID)
    }
    useEffect(() => {
        let loadContacts: contact[] = [];
        async function fetchData() {
            const unsub = onSnapshot(userRef, (user) => {
                loadContacts = user.get('chats')
                setContacts(loadContacts)
                console.log(loadContacts)
            })
        }

        fetchData()

    }, []);

    function handleChatChange (id:string) {
        console.log('prueba')
        props.onHandleChatChange(id)
    }

    return (
        <div className="flex flex-col overflow-hidden h-auto">
            <SearchBar />
            <div className="overflow-y-scroll scrollbar h-4/6 max-h-screen">
                {contacts.length >= 0 ? (
                    <ul>
                        {contacts.map(contact => (
                            <><div>
                                <Contact contact={contact} onHandleChatChange={handleChatChange}/>
                            </div></>
                        ))}
                    </ul>) : (
                    <div className='flex flex-col space-y-4'>
                        <div className='font-Poppins'>¡Wow! ¡Que vacío!</div>
                        <div className='font-Poppins'>Podrías empezar a rellenar el chat ;)</div>
                    </div>
                )}
            </div>
        </div>
    )
}

function Contact(props: {contact:contact, onHandleChatChange: (id:string) => void}) {

    const [style, setStyle] = useState<style>()
    const [username, setUsername] = useState('')

    function handleClick () {
        console.log('contact Id: ' + props.contact.id)
        props.onHandleChatChange(props.contact.id)
    }
    let userRef;

    useEffect(() => {
        async function loadUser() {

            if (props.contact.member !== undefined) {
                userRef = doc(db, 'users', props.contact.member)

                const userData = await getDoc(userRef)

                setUsername(userData.get('username'))

                setStyle({
                    backgroundImage: `url(${userData.get('profilePicture')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                })
            }
        }

        loadUser()
    }, [props.contact])



    return (
        <div className="flex flex-grow-0 p-4 mx-4 my-2 flex-row hover:bg-gray-200 rounded-xl cursor-pointer" onClick={handleClick}>
            <div className="flex-shrink-0">
                <div className="h-16 w-16 flex rounded-full shadow-md bg-cover" style={style}></div>
            </div>
            <div className="flex flex-col mx-4 justify-center">
                <p className="font-Poppins">{username}</p>
            </div>
            {/* <div className="flex flex-col justify-center">
                <div className="w-2 h-2 rounded-full bg-blue-500 mx-auto"></div>
                <p className="mx-auto">Hoy</p>
            </div> */}
        </div>
    )
}

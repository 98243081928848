import * as React from 'react';
import 'firebase/database';
import { Message, Messaging } from './messaging';
import { Contacts } from '.';
import { auth, db } from '../..';
import { addDoc, arrayUnion, collection, doc, DocumentData, DocumentReference, getDoc, updateDoc } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';





export function Chat(props: { userToMessage?: string }) {

    const user = auth.currentUser
    const [chatToLoad, setChatToLoad] = useState('exception')
    const [displayMessaging, setDisplayMessaging] = useState(false)
    const loadControl = useRef(false)
    let docRef: DocumentReference<DocumentData> | null;
    if (user !== null) {
        docRef = doc(db, "users", user.uid);
    } else {
        docRef = null
    }

    useEffect(() => {

        console.log('chatToLoad: ' + chatToLoad)

        async function handleDoc() {
            if (props.userToMessage !== undefined && docRef !== null) {

                const docSnap = await getDoc(docRef);

                let chats: chatData[] = docSnap.get('chats')
                let i: number = 1

                if (props.userToMessage === 'noChatSelected') {

                    setChatToLoad(chats[chats.length - 1].id)
                } else {
                    chats.forEach((chatData: chatData) => {

                        if (chatData.member === props.userToMessage) {
                            loadControl.current = true
                            setChatToLoad(chatData.id)

                        }


                    })
                }





                if (props.userToMessage !== 'noChatSelected' && chatToLoad === 'exception' && loadControl.current === false && user !== null) {
                    const newChat: chat = {
                        members: [user?.uid, props.userToMessage],
                        messages: []
                    }

                    const chatRef = addDoc(collection(db, 'chats'), newChat)


                    const newChatData: chatData = {
                        id: (await chatRef).id,
                        member: props.userToMessage
                    }

                    await updateDoc(docRef, {
                        chats: arrayUnion(newChatData)
                    })

                    setChatToLoad((await chatRef).id)
                }
            }
        }

        handleDoc()

    }, [props.userToMessage]);

    function handleChatChange(id: string) {
        console.log('prueba 2')
        setChatToLoad(id)
    }

    function handleChatChangeSm(id: string) {
        setDisplayMessaging(true)
        setChatToLoad(id)
    }

    return (
        <div>
            <div className='block sm:hidden'>
                {displayMessaging ? (
                    <div>
                        <div onClick={() => {setDisplayMessaging(false)}} className='w-fit text-md text-slate-500 m-4 py-2 px-4 rounded-full border-0 cursor-pointer font-semibold bg-gray-200  hover:bg-gray-300'>Volver</div>
                        <Messaging chatID={chatToLoad} member={props.userToMessage} />
                    </div>
                ) : (
                    <Contacts userUID={user?.uid} onHandleChatChange={handleChatChangeSm} />
                )}
            </div>
            <div className='hidden sm:block'>
                <div className="flex flex-row">
                    <Contacts userUID={user?.uid} onHandleChatChange={handleChatChange} />
                    {chatToLoad !== 'exception' ? (<Messaging chatID={chatToLoad} member={props.userToMessage} />) : (
                        <div className="flex flex-row w-full mx-auto bg-white rounded-xl p-4 h-60 shadow-lg m-4 justify-center">
                            <h1 className=' font-Poppins text-lg font-bold self-center'>¡Todavía no tienes ningún chat! Habla con alguien y vuelve cuando quieras :)</h1>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export interface chatData {
    id: string,
    member: string,
}

interface chat {
    members: string[],
    messages: Message[]
}

import react from 'react'

export function Footter() {
    return (
        <footer className="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
            <div className="mx-auto max-w-screen-xl text-center">
                <div className='flex flex-row w-full h-fit justify-center'>
                    <div className='bg-logo h-16 w-16 bg-cover bg-center flex self-center cursor-pointer'></div>
                </div>
                <p className="my-6 text-gray-500 dark:text-gray-400">Tu sitio web de contactos libre.</p>
                <ul className="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
                    <li>
                        <a href="#" className="mr-4 hover:underline md:mr-6 ">Sobre Nosotros</a>
                    </li>
                    <li>
                        <a href="#" className="mr-4 hover:underline md:mr-6">Términos y Condiciones</a>
                    </li>
                    <li>
                        <a href="#" className="mr-4 hover:underline md:mr-6">Contacto</a>
                    </li>
                </ul>
                <ul>
                    <li className='font-Poppins text-sm text-gray-500'> - En esta web el anuncio de la prostitución queda terminantemente prohibida.</li>
                    <li className='font-Poppins text-sm text-gray-500'> - Así mismo, si individuos que se dedican a ello utilizan nuestra plataforma para conocer gente y llegan a acuerdos por el chat privado, no está bajo nuestro control</li>
                    <li className='font-Poppins text-sm text-gray-500'> - Esto es una web de contactos.</li>
                </ul>
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022-2023 <a href="#" className="hover:underline">Contactos Libres</a>. All Rights Reserved.</span>
            </div>
        </footer>
    )
}
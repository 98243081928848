import react, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { Icon } from './icon'

export function Disclaimer() {

    const [toggle, setToggle] = useState(false)

    function handleToggle() {
        if (toggle) {
            setToggle(false)
        } else {
            setToggle(true)
        }
    }

    return (
        <div className='w-fit flex flex-row h-fit self-center justify-center'>
            {toggle ? (
                <div></div>
            ) : (
                <div className='flex flex-col w-full sm:w-2/3 bg-yellow-200 h-fit rounded-xxl'>
                    <div className='flex flex-col space-y-2 py-4 px-10'>
                        <div className='flex flex-row justify-between'>
                            <h3 className=' font-Poppins font-bold text-yellow-700 self-center'>Nota importante:</h3>
                            <Icon onPress={() => { setToggle(true) }} style={'hover:bg-yellow-300 rounded-full cursor-pointer'}>
                                <MdClose className='h-5 w-5 text-yellow-700' />
                            </Icon>
                        </div>
                        <ul className='text-left'>
                            <li className='font-Poppins text-sm text-yellow-700'> - Esta web esta en construcción. Iremos implementando mejoras. Gracias por la paciencia.</li>
                            <li className='font-Poppins text-sm text-yellow-700'> - Enviad vuestras sugerencias a <b>echopc.contacto@gmail.com</b></li>
                        </ul>
                        <h3 className=' font-Poppins font-bold text-yellow-700 self-start'>Novedades</h3>
                        <ul className='text-left'>
                            <li className='font-Poppins text-sm text-yellow-700'> - Ya se pueden editar los anuncios.</li>
                        </ul>
                    </div>
                </div>
            )}
        </div>

    )
}
import { useEffect, useState } from 'react';
import { options } from '../App';
import { AnounceBulletin } from '../components/AnounceBulletin';
import { auth, db } from '..';
import { deleteDoc, doc, DocumentData, DocumentReference, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { PurchaseCards } from '../components/purchaseCard';
import { useParams } from 'react-router';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from 'react-stripe-js';
import { User } from 'firebase/auth';
import { functions } from '..';
import { httpsCallable, httpsCallableFromURL, HttpsCallableResult } from "firebase/functions";
import { Token } from 'typescript';
import { Icon } from '../components/icon';
import { BiLoaderAlt } from 'react-icons/bi'


interface userData {
    username: string,
    email: string,
    profilePicture: string,
    imageCollection: string[],
    tokens: tokens,
}

export interface tokens {
    purchased: number,
    transferred: number,
}
interface payment {
    id: string,
    amount: number,
    status: string,
}

export function Profile(props: { onPageChange: (newPage: options) => void, onHandleChatID: (id: string) => void , onAnounceID: (id: string) => void}) {

    const [user, setUser] = useState<User | null>(auth.currentUser);
    let stripe = loadStripe('pk_test_51HY7zgIDYA0cwdTySJ2wEW4lOhFZr7NADnIZrL8ym78aUrUitE9CIpPHf1X8q19q1KlsIg55sohP5We7nP5eJCXU00bSG2GIUB');


    let [userData, setUserData] = useState<userData>();
    const [purchase, setPurchase] = useState<boolean>()
    const [purchased, setPurchased] = useState<boolean>()
    const [lookingPurchased, setLookingPurchased] = useState<boolean>()
    const [errorPurchasing, setErrorPurchasing] = useState<boolean>()
    let userRef: DocumentReference<DocumentData> | null;
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const token = urlParams.get('token')

    console.log('token: ' + token)

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (token !== null) {
            setLookingPurchased(true)
            handleAddTokens(token)
        }
    }, [user])


    async function handleAddTokens(token: string) {
        if (user) {
            const checkoutSessionId = token;

            let confirmedPayment = false

            console.log('paso 1')

            const retrieveCheckoutSession = httpsCallableFromURL(functions, 'https://europe-west3-prueba-ai-6c71c.cloudfunctions.net/retrieveCheckoutSession')

            await retrieveCheckoutSession({ id: checkoutSessionId, userID: user.uid });

            if (user) {

                const paymentRef = doc(db, 'payments', user.uid)

                const unsub = onSnapshot(paymentRef, async (payment) => {
                    console.log('paso 2')


                    if (payment.get('status') === 'paid') {
                        console.log('paso 3')
                        confirmedPayment = true

                    } else {
                        setErrorPurchasing(true)
                    }

                    if (confirmedPayment) {

                        console.log('paso 4')
                        await addTokens(payment)

                        unsub()

                        await deleteDoc(paymentRef)

                        setPurchased(true)

                        // eslint-disable-next-line no-restricted-globals
                        history.pushState(null, 'Profile', 'https://contactoslibres.com/');
                        console.log('paso 5')
                    } else {
                        setErrorPurchasing(true)
                    }
                })
            }
        }
    }

    async function addTokens(payment: DocumentData) {

        let user = auth.currentUser

        if (user) {
            let docRef = doc(db, 'users', user.uid)

            let userSnap = getDoc(docRef)

            let tokens: tokens = (await userSnap).get('tokens')

            await updateDoc(docRef, {
                tokens: {
                    purchased: tokens.purchased + payment.get('amount'),
                    transferred: tokens.transferred,
                }
            })
        }
    }


    useEffect(() => {

        if (user !== null) {
            userRef = doc(db, "users", user.uid);
        } else {
            userRef = null
        }

        console.log('userRef: ' + user + userRef)
        async function handleUserData() {

            if (userRef) {
                let userSnap = await getDoc(userRef)

                let auxData: userData = {
                    username: userSnap.get('username'),
                    email: userSnap.get('email'),
                    profilePicture: userSnap.get('profilePicture'),
                    imageCollection: userSnap.get('imageCollection'),
                    tokens: userSnap.get('tokens')
                }

                setUserData(auxData)

            }
        }

        handleUserData()

    }, [user])

    function togglePurchase() {
        if (purchase) {
            setPurchase(false)
        } else {
            setPurchase(true)
        }
    }

    return (

        <Elements stripe={null}>
            <div>
                {userData ? (
                    <div className="flex flex-col items-center p-4">
                        {/* profile picture */}
                        <img src={userData.profilePicture} alt="Profile picture" className="w-32 h-32 rounded-full" />

                        {/* username */}
                        <h2 className="font-bold text-2xl mt-4">{userData.username}</h2>


                        {/* token amount */}
                        <div className='flex flex-row space-x-3 h-fit mt-4'>
                            <div className=" flex bg-gray-200 rounded-lg p-4">
                                <h3 className="font-bold">Token amount: {userData.tokens.purchased + userData.tokens.transferred}</h3>
                            </div>
                            <div className='h-10 w-10 bg-addToken bg-cover bg-center flex self-center hover:rotate-90 transition-all cursor-pointer' onClick={togglePurchase}></div>
                        </div>
                        {purchase ? (
                            <div>
                                <PurchaseCards />
                            </div>
                        ) : purchased ? (
                            <div className="flex flex-row flex-wrap justify-center 2xl:justify-evenly 2xl:flex-nowrap flex-grow w-full mx-auto bg-white rounded-xl p-4 h-fit shadow-lg my-4">
                                <div className='flex flex-row'>
                                    <div className='font-semibold text-gray-700 h-full py-2 px-4 rounded-lg font-Poppins flex flex-col justify-center space-y-2 flex-shrink md:flex-shrink-0'>
                                        <p className='font-Poppins text-gray-700'> ¡Gracias por tu compra!</p>
                                        <p className=' font-Poppins text-xs text-gray-500'>Actualiza la página para ver los resultados.</p>
                                    </div>
                                </div>
                            </div>
                        ) : lookingPurchased ? (
                            <div className="flex flex-row 2xs:flex-wrap sm:justify-center 2xl:justify-evenly 2xl:flex-nowrap flex-grow w-full mx-auto bg-white rounded-xl p-4 h-fit shadow-lg my-4">
                                <div className='flex flex-row'>
                                    <div className='font-semibold text-gray-700 h-full py-2 px-4 rounded-lg font-Poppins flex flex-col justify-center sm:flex-shrink md:flex-shrink-0'>
                                        <div className='flex flex-row space-x-1'>
                                            <Icon onPress={() => null}>
                                                <BiLoaderAlt className='h-6 w-6 text-gray-700 animate-spin'/>
                                            </Icon>
                                            <p className='font-Poppins text-gray-700 self-center'> Estamos comprobando tu compra... Esto no tardará mucho</p>
                                        </div>
                                        <p className=' font-Poppins text-xs text-gray-500'>No actualices la página, esto puede tardar varios minutos.</p>
                                    </div>
                                </div>
                            </div>
                        ) : errorPurchasing ? (
                            <div className="flex flex-row 2xs:flex-wrap justify-center 2xl:justify-evenly 2xl:flex-nowrap flex-grow w-full mx-auto bg-white rounded-xl p-4 h-fit shadow-lg my-4">
                                <div className='flex flex-row'>
                                    <div className='font-semibold text-gray-700 h-full py-2 px-4 rounded-lg font-Poppins flex flex-col justify-center flex-shrink md:flex-shrink-0'>
                                        <p className='font-Poppins text-red-500'> Ha ocurrido un error con tu compra.</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}


                        {/* image collection */}
                        <div className="mt-4">
                            <div className='flex flex-row w-full justify-center my-2'>
                                <h1 className=' font-Poppins text-lg text-gray-700'>Tus Imagenes:</h1>
                            </div>
                            {userData.imageCollection.map((imageUrl) => (
                                <img src={imageUrl} alt="Collection image" className="w-32 h-32 rounded-lg m-2" />
                            ))}
                            <p className=' font-Poppins text-xs text-gray-500'>Proximamente...</p>
                        </div>


                        {/* <AnounceBulletin /> component */}
                        <div className="mt-4 w-full">
                            <AnounceBulletin onPageChange={props.onPageChange} onHandleChatID={props.onHandleChatID} profile={true} onAnounceID={props.onAnounceID} />
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-row w-full h-20 justify-center'>
                        <h1 className=' font-Poppins text-lg text-gray-700'>Error. No hemos podido cargar tu perfil. ¡Asegurate de haber iniciado sesión!</h1>
                    </div>
                )}

            </div>
        </Elements>

    );
};



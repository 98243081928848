import * as React from 'react';
import { useEffect, useState } from 'react';




const locations =  ['A Coruña','Álava', 'Albacete', 'Alicante', 'Almería', 'Asturias', 'Ávila', 'Badajoz', 'Baleares', 'Barcelona', 'Burgos', 'Cáceres', 'Cádiz', 'Cantabria', 'Castellón', 'Ciudad Real', 'Córdoba', 'Cuenca', 'Girona', 'Granada', 'Guadalajara', 'Gipuzkoa', 'Huelva', 'Huesca', 'Jaén', 'La Rioja', 'Las Palmas', 'León', 'Lérida', 'Lugo', 'Madrid', 'Málaga', 'Murcia', 'Navarra', 'Ourense', 'Palencia', 'Pontevedra', 'Salamanca', 'Segovia', 'Sevilla', 'Soria', 'Tarragona', 'Santa Cruz de Tenerife', 'Teruel', 'Toledo', 'Valencia', 'Valladolid', 'Vizcaya', 'Zamora', 'Zaragoza']


export function LocationSelect(props: { onHandleLocation: (category: string) => void}) {
    const [selectedLocation, setSelectedLocation] = useState('');

    const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLocation(event.target.value);
        console.log(event.target.value)
    };


    useEffect(() => {
        props.onHandleLocation(selectedLocation)
    }, [selectedLocation])

    return (
        <div className='flex flex-col justify-center'>
            <div className='flex flex-row space-x-3 self-center'>
                <div className="relative rounded-md flex flex-col justify-center">
                    <select
                        className="form-input py-2 px-3 block w-full font-Poppins font-semibold text-gray-700 leading-5 rounded-lg transition duration-150 ease-in-out bg-gray-100 border border-gray-300 appearance-none focus:outline-none focus:shadow-outline-blue focus:border-gray-500 sm:text-sm sm:leading-5"
                        value={selectedLocation}
                        onChange={handleCategoryChange}
                    >
                        <option value="" disabled>
                            Ubicación
                        </option>
                        {locations.map((location) => (
                            <option key={location} value={location}>
                                {location}
                            </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

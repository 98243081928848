import React, { useState, useEffect, useRef } from 'react';
import * as firebase from 'firebase/app';
import { collection, DocumentData, getDocs, limit, orderBy, Query, query, QueryDocumentSnapshot, startAfter, Timestamp, where } from "firebase/firestore";
import { AnounceCard } from './AnounceCard';
import { db, auth } from '../index';
import { AnounceSearch } from './AnounceSearch';
import { Login } from '../pages/login';
import { options } from '../App';
import { Disclaimer } from './disclaimer';



export function AnounceBulletin(props: { onPageChange: (newPage: options) => void, onAnounceID: (id: string) => void, onHandleChatID: (id: string) => void, profile: boolean }) {
  const [anounces, setAnounces] = useState<doc[]>([]);

  const [index, setIndex] = useState(0)
  const [searched, setSearched] = useState(false)
  const [last, setLast] = useState(false)
  const [paginationPrev, setPaginationPrev] = useState<QueryDocumentSnapshot<DocumentData>>()
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData>>()
  const [startFrom, setStartFrom] = useState<QueryDocumentSnapshot<DocumentData>>()

  const [userCount, setUserCount] = useState(0)

  let indexedDocs: indexDoc = {}

  const [firstSearch, setFirstSearch] = useState(true)

  const [searchInput, setSearchInput] = useState('')
  const [locationInput, setLocationInput] = useState('')
  const [categoryInput, setCategoryInput] = useState(0)

  const user = useRef(auth.currentUser)



  useEffect(() => {
    const options: string[] = ['anouncesPlus', 'anounces']

    console.log('startFrom: ' + startFrom)

    setUserCount(Math.round(Math.random() * (260 - 230) + 230))


    async function loadBulletin() {
      if (!searched) {

        // Query the "anounces" collection in Firestore
        let collRef;
        let q;
        const newAnounces = new Array<doc>()

        for (const option of options) {
          if (newAnounces.length <= 25) {
            if (index !== 0) {
              setPaginationPrev(lastVisible)

            }
            indexedDocs[index] = lastVisible
            console.log('indexedDocs' + indexedDocs[0])
            if (props.profile) {
              // Query the "anounces" collection in Firestore
              collRef = collection(db, option)
              if (index === 0) {
                q = query(collRef, orderBy('createdAt', 'desc'), where('postedBy', '==', user.current?.uid), limit(25 - newAnounces.length))
              } else {

                q = query(collRef, orderBy('createdAt', 'desc'), where('postedBy', '==', user.current?.uid), startAfter(startFrom), limit(25 - newAnounces.length))
              }


            } else {// Query the "anounces" collection in Firestore
              collRef = collection(db, option)
              console.log('newAnounces length: ' + newAnounces.length)
              if (index === 0) {
                q = query(collRef, orderBy('createdAt', 'desc'), limit(25 - newAnounces.length))
              } else {
                console.log('ha entrado aqui')
                q = query(collRef, orderBy('createdAt', 'desc'), startAfter(startFrom), limit(25 - newAnounces.length))
              }
            }



            // Get the results of the query
            const docList = await getDocs(q);
            console.log(docList)
            // Create an array of "doc" objects from the query results

            docList.forEach((doc) => {
              let auxDesc = doc.get('description')
              auxDesc = auxDesc.split('||')
              let aux: doc = {
                id: doc.id,
                postedBy: doc.get('postedBy'),
                title: doc.get('title'),
                description: auxDesc[1],
                location: doc.get('location'),
                createdAt: doc.get('createdAt'),
                imageRef: doc.get('imageRef'),
                plus: doc.get('plus'),
              }

              if (option === 'anouncesPlus') {
                aux = {
                  ...aux,
                  selectedTime: doc.get('selectedTime')
                }
              }
              newAnounces.push(aux)
              setLastVisible(doc)
            })


            setAnounces([])
            setAnounces(newAnounces);
            console.log(newAnounces)



          }

          // Update the anounces state with the new array of anounces

        }
        if (newAnounces.length < 25) {
          setLast(true)
        }
      } else (
        anounceSearch(searchInput, locationInput, categoryInput)
      )
    }


    loadBulletin()

  }, [index]);

  async function anounceSearch(searchInput: string, LocatioInput: string, category: number) {

    setSearched(true)
    setSearchInput(searchInput)
    setLocationInput(LocatioInput)
    setCategoryInput(category)

    if (firstSearch) {
      setIndex(0)
      setFirstSearch(false)
    }

    const options = ['anouncesPlus', 'anounces']
    // Query the "anounces" collection in Firestore
    let collRef;
    let q;



    // Create an array of "doc" objects from the query results
    const filteredAnounces = new Array<doc>()

    for (const option of options) {
      if (filteredAnounces.length <= 25) {

        if (index !== 0) {
          setPaginationPrev(lastVisible)

        }
        indexedDocs[index] = lastVisible

        collRef = collection(db, option)

        if (index === 0) {
          if (category === 0) {
            if (LocatioInput) {
              // If only LocatioInput is not empty, filter the results by the "location" field
              q = query(collRef, orderBy('createdAt', 'desc'), where('location', '==', LocatioInput), limit(25 - filteredAnounces.length))
            } else {
              // If searchInput and LocatioInput are empty, return all results
              q = query(collRef, orderBy('createdAt', 'desc'), limit(25 - filteredAnounces.length))
            }
          } else {
            if (LocatioInput) {
              // If only LocatioInput is not empty, filter the results by the "location" field
              q = query(collRef, orderBy('createdAt', 'desc'), where('location', '==', LocatioInput), where('category', '==', category), limit(25 - filteredAnounces.length))
            } else {
              // If searchInput and LocatioInput are empty, return all results
              q = query(collRef, orderBy('createdAt', 'desc'), where('category', '==', category), limit(25 - filteredAnounces.length))
            }
          }
        } else {
          if (category === 0) {
            if (LocatioInput) {
              // If only LocatioInput is not empty, filter the results by the "location" field
              q = query(collRef, orderBy('createdAt', 'desc'), where('location', '==', LocatioInput), startAfter(startFrom), limit(25 - filteredAnounces.length))
            } else {
              // If searchInput and LocatioInput are empty, return all results
              q = query(collRef, orderBy('createdAt', 'desc'), startAfter(startFrom), limit(25 - filteredAnounces.length))
            }
          } else {
            if (LocatioInput) {
              // If only LocatioInput is not empty, filter the results by the "location" field
              q = query(collRef, orderBy('createdAt', 'desc'), where('location', '==', LocatioInput), where('category', '==', category), startAfter(startFrom), limit(25 - filteredAnounces.length))
            } else {
              // If searchInput and LocatioInput are empty, return all results
              q = query(collRef, orderBy('createdAt', 'desc'), where('category', '==', category), startAfter(startFrom), limit(25 - filteredAnounces.length))
            }
          }
        }


        // Get the results of the query
        if (q !== undefined) {
          const docList = await getDocs(q);


          docList.forEach((doc) => {
            let auxDesc: string = doc.get('description')
            let auxDescArray: string[] = auxDesc.split('||')
            let aux: doc = {
              id: doc.id,
              postedBy: doc.get('postedBy'),
              title: doc.get('title'),
              description: auxDescArray[1],
              location: doc.get('location'),
              createdAt: doc.get('createdAt'),
              imageRef: doc.get('imageRef'),
              plus: doc.get('plus')
            }
            if (option === 'anouncesPlus') {
              aux = {
                ...aux,
                selectedTime: doc.get('selectedTime')
              }
            }
            if (searchInput) {
              let index = auxDesc.toLocaleLowerCase().includes(searchInput.toLowerCase())
              if (index) {
                filteredAnounces.push(aux)
                setLastVisible(doc)
              }
            } else {
              filteredAnounces.push(aux)
              setLastVisible(doc)
            }
          })

          // Update the anounces state with the new array of anounces


          console.log(filteredAnounces)
          setAnounces([])
          console.log('setting anounces')
          setAnounces(filteredAnounces);
        }

      }

    }


    if (filteredAnounces.length < 25) {
      setLast(true)
    }



  }


  function handleStartFrom(i: number) {
    if (i === 0) {
      console.log('clicked Prev')
      setStartFrom(paginationPrev)
      setLast(false)
      setIndex(index - 25)
    } else if (i === 1) {
      console.log('clicked Next')
      setStartFrom(lastVisible)
      setIndex(index + 25)
    }
  }

  return (
    <div >
      {props.profile ? (
        <div className='flex flex-row w-full justify-center'>
          <h1 className=' font-Poppins text-lg text-gray-700'>Tus Anuncios:</h1>
        </div>
      ) : (
        <div className='h-fit md:h-20 my-2 py-5 w-full flex flex-row justify-center'>
          <AnounceSearch onSearchButton={anounceSearch} />
        </div>
      )}
      <div className=' w-full flex flex-row justify-center mb-4'>
        <Disclaimer />
      </div>

      <div className='flex flex-row w-full justify-center'>
        <div className='bg-white flex flex-row space-x-2 w-fit h-fit px-4 py-2 rounded-xl font-Poppins font-semibold shadow-sm mb-4'>
          <div>
            Usuarios activos: {userCount}
          </div>
          <div className='flex flex-col justify-center h-full'>
            <div className="absolute h-2 w-2 rounded-md bg-blue-600 mt-6 animate-ping"></div>
            <div className="absolute h-2 w-2 rounded-md bg-blue-600 mt-6"></div>
          </div>

        </div>

      </div>

      <div className='flex flex-col w-full sm:px-5 md:px-10 lg:px-20'>
        {anounces.map((anounce) => (
          <AnounceCard key={anounce.id} anounce={anounce} onPageChange={props.onPageChange} onHandleChatID={props.onHandleChatID} onAnounceID={props.onAnounceID} postedBy={anounce.postedBy} page={false} />
        ))}
      </div>

      <div className='flex flex-row w-full justify-center space-x-2'>
        {/* {index === 0 ? (
          <button onClick={() => { handleStartFrom(0) }} disabled>Anterior</button>
        ) : (
          <button onClick={() => { handleStartFrom(0) }}>Anterior</button>
        )} */}
        {last ? (

          <div className='flex flex-row justify-center space-x-2'>
            <button onClick={() => { window.location.reload() }} className={'w-fit text-md text-slate-500 m-4 py-2 px-4 rounded-full border-0 cursor-pointer font-semibold bg-gray-200  hover:bg-gray-300'}>Volver al Inicio</button>
            <button onClick={() => { handleStartFrom(1) }} className={'w-fit text-md text-slate-300 m-4 py-2 px-4 rounded-full border-0 font-semibold bg-gray-200'} disabled>Siguiente</button>
          </div>

        ) : (
          <button onClick={() => { handleStartFrom(1) }} className={'w-fit text-md text-slate-500 m-4 py-2 px-4 rounded-full border-0 cursor-pointer font-semibold bg-gray-200  hover:bg-gray-300'}>Siguiente</button>
        )}

      </div>
    </div>
  );
}
interface doc {
  id: string;
  postedBy: string;
  title: string;
  description: string;
  location: string;
  createdAt: Timestamp;
  imageRef: string;
  plus: boolean,
  selectedTime?: number
}

interface indexDoc {
  [key: number]: QueryDocumentSnapshot<DocumentData> | undefined

}
import { doc, getDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../..'
import user from '../../data/user.json'

interface style {
    backgroundImage: string,
    backgroundSize: string,
    backgroundPosition: string,
    boxShadow: string

}

export function Header(props: { userToMesage: string | undefined }) {

    const [username, setUsername] = useState('')
    const [style, setStyle] = useState<style>()

    let userRef;

    useEffect(() => {
        async function loadUser() {

            if (props.userToMesage !== undefined) {
                userRef = doc(db, 'users', props.userToMesage)

                const userData = await getDoc(userRef)

                console.log(userData.get('profilePicture'))

                setUsername(userData.get('username'))

                setStyle({
                    backgroundImage: `url(${userData.get('profilePicture')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                })
            }
        }

        loadUser()
    }, [props.userToMesage])



    return (
        <div className="flex flex-row px-16">
            <div className="flex-shrink-0">
                <div className="h-24 w-24 flex rounded-full shadow-md bg-cover" style={style}></div>
            </div>
            <p className="font-Poppins my-auto font-bold text-2xl ml-4">
                {username}
            </p>
        </div>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { db, auth } from '../index'
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { CategorySelect } from './CategorySelect';
import FileUploadButton from './FileUploadButton';
import { LocationSelect } from './locationSelect';
import { Icon } from './icon';
import { HiPlus } from 'react-icons/hi'

export function AnounceForm() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState(0)
  const [imageRef, setImageRef] = useState('https://firebasestorage.googleapis.com/v0/b/prueba-ai-6c71c.appspot.com/o/utils%2Flogo.png?alt=media&token=09808ad2-95e3-43f4-b832-7f7aa7b0d140')
  const [imageCol, setImageCol] = useState<string[]>([])
  const [successfullyPublished, setSuccessfullyPublished] = useState(false)
  const [image1, setImage1] = useState(false)
  const [image2, setImage2] = useState(false)
  const [image3, setImage3] = useState(false)
  const [image4, setImage4] = useState(false)
  const [image5, setImage5] = useState(false)
  const path = useRef('')

  const user = auth.currentUser

  useEffect(() => {
    if (user) {
      path.current = 'users/' + user.uid + '/'
    }
  }, [])

  function handleCategory(category: number) {
    setCategory(category)
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let modifDesc = title + '||' + description
    const docRef = await addDoc(collection(db, 'anounces'), {
      id: uuid(),
      postedBy: user?.uid,
      title,
      description: modifDesc,
      location,
      category,
      createdAt: Timestamp.now(),
      imageRef,
      imageCol,
      plus: false,
    })
      .then(() => {
        setTitle('');
        setDescription('');
        setLocation('');
        setCategory(0);
        setImageRef('')
        setSuccessfullyPublished(true)
      });
  };

  function handleImageRef(imageRef: string) {
    setImageCol([...imageCol, imageRef])
    setImageRef(imageRef)
  }
  function handleImageCol(imageRef: string) {
    setImageCol([...imageCol, imageRef])
    console.log(imageCol)
  }

  function handleLocation(location: string) {
    setLocation(location)
  }

  return (
    <div className='py-20 sm:px-2 md:px-10 lg:px-30 xl:px-50 2xl:px-80'>
      {successfullyPublished ? (
        <div className="flex flex-row w-full mx-auto bg-white rounded-xl p-4 h-24 shadow-lg m-4">
          <h1 className=' font-Poppins text-lg font-bold self-center'>¡Anuncio publicado correctamente!</h1>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block font-bold text-gray-700 mb-1 font-Poppins"
            >
              Título
            </label>
            <input
              type="text"
              id="title"
              className="border border-gray-300 bg-gray-50 rounded-lg w-full py-2 px-3 font-Poppins"
              maxLength={50}
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block font-bold text-gray-700 mb-1 font-Poppins"
            >
              Descripción
            </label>
            <textarea
              id="description"
              rows={6}
              className="border border-gray-300 bg-gray-50 rounded-lg w-full py-2 px-3 font-Poppins"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="location"
              className="block font-bold text-gray-700 mb-1 font-Poppins"
            >
              Ubicación
            </label>
            <LocationSelect onHandleLocation={handleLocation} />
          </div>
          <div className='flex flex-col space-y-4 justify-center px-6 sm:px-0 sm:space-y-0 sm:flex-row sm:justify-around'>
            <CategorySelect onHandleCategory={handleCategory} form={true} />
            <div className='flex flex-col space-y-2'>
              <div className='flex flex-row space-x-2'>
                <FileUploadButton onHandleFileUpload={handleImageRef} path={path.current} first={true}/>
                <div className='flex flex-col justify-end'>
                  <Icon onPress={() => { setImage1(true) }} style={'justify-center hover:bg-gray-200 rounded-full h-fit w-fit'}>
                    <HiPlus className='h-7 w-7 self-center text-gray-600 hover:rotate-90' />
                  </Icon>
                </div>
              </div>
              {image1 ? (
                <div className='flex flex-row space-x-2'>
                  <FileUploadButton onHandleFileUpload={handleImageCol} path={path.current} first={false}/>
                  <div className='h-full flex flex-col justify-end'>
                    <Icon onPress={() => { setImage2(true) }} style={'justify-center hover:bg-gray-200 rounded-full h-fit w-fit'}>
                      <HiPlus className='h-7 w-7 self-center text-gray-600 hover:rotate-90' />
                    </Icon>
                  </div>
                </div>
              ) : (null)}
              {image2 ? (
                <div className='flex flex-row space-x-2'>
                  <FileUploadButton onHandleFileUpload={handleImageCol} path={path.current} first={false}/>
                  <div className='h-full flex flex-col justify-end'>
                    <Icon onPress={() => { setImage3(true) }} style={'justify-center hover:bg-gray-200 rounded-full h-fit w-fit'}>
                      <HiPlus className='h-7 w-7 self-center text-gray-600 hover:rotate-90' />
                    </Icon>
                  </div>
                </div>
              ) : (null)}
              {image3 ? (
                <div className='flex flex-row space-x-2'>
                  <FileUploadButton onHandleFileUpload={handleImageCol} path={path.current} first={false}/>
                  <div className='h-full flex flex-col justify-end'>
                    <Icon onPress={() => { setImage4(true) }} style={'justify-center hover:bg-gray-200 rounded-full h-fit w-fit'}>
                      <HiPlus className='h-7 w-7 self-center text-gray-600 hover:rotate-90' />
                    </Icon>
                  </div>
                </div>
              ) : (null)}
              {image4 ? (
                <div className='flex flex-row space-x-2'>
                  <FileUploadButton onHandleFileUpload={handleImageCol} path={path.current} first={false}/>
                  <div className='h-full flex flex-col justify-end'>
                    <Icon onPress={() => { setImage5(true) }} style={'justify-center hover:bg-gray-200 rounded-full h-fit w-fit'}>
                      <HiPlus className='h-7 w-7 self-center text-gray-600 hover:rotate-90' />
                    </Icon>
                  </div>
                </div>
              ) : (null)}
              {image5 ? (
                <div className='flex flex-row space-x-2'>
                  <FileUploadButton onHandleFileUpload={handleImageCol} path={path.current} first={false}/>
                </div>
              ) : (null)}
            </div>
          </div>
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-16 my-4 rounded-lg font-Poppins">
            Publicar
          </button>
        </form>
      )}

    </div>
  );
};

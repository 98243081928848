import React from 'react'
import { HiOutlineSearch } from 'react-icons/hi'

export function SearchBar(): JSX.Element {
    return (
        <div className="m-4 flex flex-row bg-gray-100 rounded-xl justify-center">
            <HiOutlineSearch className="w-6 h-6 my-auto ml-2 text-blue-600" />
            <input
                className="flex flex-grow w-full p-2 bg-transparent font-Poppins focus:outline-none my-auto"
                placeholder={'Buscar...'}
            ></input>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { options } from '../App'
import { auth } from '..'
import { Icon } from './icon'
import { MdAddBox, MdOutlineChat } from 'react-icons/md'
import { FiLogOut, FiUser } from 'react-icons/fi'
import { AiFillHeart } from 'react-icons/ai'

export function NavBar(props: { onPageChange: (newPage: options) => void }) {

    const [user, setUser] = useState(auth.currentUser)

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    const AddIconStyle = ['h-10', 'w-10', 'text-gray-700', 'self-center', 'cursor-pointer'].join(' ')
    const frameStyle = ['hover:bg-gray-200', 'cursor-pointer', 'rounded-lg', 'h-fit'].join(' ')

    const handleLogout = async () => {
        try {
            await auth.signOut();
            props.onPageChange(options.bulletin)

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>

            <div className='h-20 w-full px-0 md:px-36 flex flex-row bg-white justify-between shadow-md'>
                <div className='bg-logo h-16 w-16 bg-cover bg-center flex self-center cursor-pointer' onClick={() => { window.location.href = 'https://contactoslibres.com'}}></div>
                {user ? (<>
                    <div className="flex flex-row py-2 mr-2">
                        <div className='flex flex-col justify-center h-full'>
                            <Icon onPress={() => props.onPageChange(options.form)} style={frameStyle}>
                                <MdAddBox className={AddIconStyle} />
                            </Icon>
                        </div>
                        <div className='flex flex-col justify-center h-full'>
                            <Icon onPress={() => props.onPageChange(options.chat)} style={frameStyle}>
                                <MdOutlineChat className={AddIconStyle} />
                            </Icon>
                        </div>
                        <div className='flex flex-col justify-center h-full'>
                            <Icon onPress={() => props.onPageChange(options.profile)} style={frameStyle}>
                                <FiUser className={AddIconStyle} />
                            </Icon>
                        </div>
                        <div className='flex flex-col justify-center h-full'>
                            <button onClick={handleLogout} className=" hover:bg-red-300 font-bold flex flex-row justify-center h-fit p-2 m-2 self-center rounded-lg"><FiLogOut className="h-10 w-10 text-red-500 self-center" /></button>
                        </div>
                    </div>

                </>) : (
                    <div className='flex flex-col h-full w-fit justify-center'>
                        <div className='group bg-gradient-to-r h-fit from-indigo-500 via-purple-500 to-pink-500 hover:from-pink-500 hover:via-indigo-500 hover:to-purple-500 text-white font-bold p-[2px] rounded-xxl'>
                            <div className="bg-white text-white font-bold py-2 px-4 rounded-xxl font-Poppins hover:transition hover:duration-300 hover:ease-in-out" >
                                <h3 className='font-Poppins text-sm sm:text-base text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 group-hover:from-pink-500 group-hover:via-indigo-500 group-hover:to-purple-500 cursor-pointer' onClick={() => { props.onPageChange(options.login) }}>Iniciar Sesión</h3>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </>
    )
}
import { doc, getDoc, Timestamp } from 'firebase/firestore'
import react, { useEffect, useRef, useState } from 'react'
import { IoMdShareAlt } from 'react-icons/io'
import { MdOutlineContentCopy } from 'react-icons/md'
import { db } from '..'
import { options } from '../App'
import { AnounceCard } from '../components/AnounceCard'
import { Icon } from '../components/icon'

export function AnouncePage(props: { id: string, onPageChange: (newPage: options) => void, onHandleChatID: (id: string) => void, onAnounceID: (id: string) => void }) {

    const [anounce, setAnounce] = useState<anounce>()
    const postedBy = useRef('')
    const [imageRef, setImageRef] = useState('')
    const [imageCollection, setImageCollection] = useState<boolean>()
    const [imageCol, setImageCol] = useState<string[]>([])
    const [share, setShare] = useState('')
    const [copied, setCopied] = useState(false)

    useEffect(() => {

        console.log('props.ID: ' + props.id)

        async function loadAnounce() {

            let docRef = doc(db, 'anounces', props.id)

            let docSnap = await getDoc(docRef)

            if (docSnap.get('plus') === undefined) {
                docRef = doc(db, 'anouncesPlus', props.id)

                docSnap = await getDoc(docRef)
            }

            let auxDesc: string = docSnap.get('description')
            let auxDescArray: string[] = auxDesc.split('||')

            const anounce: anounce = {
                id: docSnap.id,
                title: docSnap.get('title'),
                description: auxDescArray[1],
                location: docSnap.get('location'),
                createdAt: docSnap.get('createdAt'),
                imageRef: docSnap.get('imageRef'),
                plus: docSnap.get('plus'),
                selectedTime: docSnap.get('selectedTime')
            }

            postedBy.current = docSnap.get('postedBy')
            setImageRef(docSnap.get('imageRef'))

            setImageCol(docSnap.get('imageCol'))
            setShare('https://contactoslibres.com/?a=' + anounce.id)


            console.log(anounce)

            setAnounce(anounce)

        }

        loadAnounce()
    }, [props.id])

    function handleCopy() {
        navigator.clipboard.writeText(share)
        setCopied(true)
    }

    function handleShare() {
        if (navigator.share) {
            navigator.share({
              title: 'Contactos Libres',
              text: anounce?.title,
              url: share,
            });
          }
    }

    function handleHomePage(){
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, 'Profile', 'https://contactoslibres.com/');
        props.onPageChange(options.bulletin)
    }

    return (
        <div className='flex flex-col space-y-10 w-full h-full py-10'>
            {anounce ? (
                <div>
                    <div onClick={handleHomePage} className='w-fit text-md text-slate-500 m-4 py-2 px-4 rounded-full border-0 cursor-pointer font-semibold bg-gray-200  hover:bg-gray-300'>Volver</div>
                    <AnounceCard anounce={anounce} onPageChange={props.onPageChange} onHandleChatID={props.onHandleChatID} postedBy={postedBy.current} page={true} onAnounceID={props.onAnounceID} />
                    <div className="flex flex-col sm:flex-row w-full mx-auto bg-white rounded-xl space-y-2 sm:space-y-0 sm:space-x-4 p-4 h-fit flex-grow sm:h-24 shadow-lg mb-4">
                        <h3 className=' font-Poppins font-bold text-md self-center'>Compartir</h3>
                        <div className='flex flex-row w-full'>
                            <input type="text" value={share} className={'w-full focus:outline-none font-Poppins border-2 pl-4 border-gray-300 rounded-lg'} />
                            <Icon onPress={handleCopy} style={'rounded-full hover:bg-gray-200 cursor-pointer hidden sm:block'}>
                                <MdOutlineContentCopy className='h-8  w-8 text-gray-600' />
                            </Icon>
                            <Icon onPress={handleShare} style={'rounded-full hover:bg-gray-200 cursor-pointer block'}>
                                <IoMdShareAlt className='h-8  w-8 text-gray-600' />
                            </Icon>
                        </div>
                        {copied ? (
                            <div className='flex flex-row w-full sm:w-fit sm:flex-col justify-center h-full'>
                                <div className='flex flex-row justify-center w-fit bg-green-200 text-green-600 py-2 px-4 rounded-full font-Poppins'>Copiado.</div>
                            </div>
                        ) : (null)}
                    </div>
                </div>
            ) : (null)}

            {imageCol ? (
                <div className='grid justify-center space-y-4 h-full w-full'>
                    {imageCol.map((image) => (
                        <div className='flex flex-row w-full justify-center '>
                            <img src={image} alt="" className='rounded-xl shadow-md' />
                        </div>
                    ))}
                </div>
            ) : (
                <div>
                    <div className='w-full flex flex-row justify-center'>
                        <img src={imageRef} alt="" className='rounded-xl'/>
                    </div>
                </div>
            )}

        </div>
    )
}

interface anounce {
    id: string;
    title: string;
    description: string;
    location: string;
    createdAt: Timestamp;
    imageRef: string;
    plus: boolean;
    selectedTime?: number
};
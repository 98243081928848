import React from 'react'
import { Message } from './messaging'
import { auth } from '../../index'
import { timestampToStringHour } from '../../utils'



function MessageBulb(props:{message:string, position:string}): JSX.Element {
    let constainerStyle: string[] = [
        'p-2',
        'bg-gray-100',
        'm-2 ',
        'rounded-xxl',
        'w-auto',
        'my-auto',
        'flex',
        'flex-wrap',
        'max-w-sm'
    ]
    let textStyle: string[] = ['font-Poppins', 'mx-2', 'font-bold', 'flex', 'flex-wrap', 'max-w-full', 'break-all']
    if (props.position === 'left') {
        constainerStyle = [...constainerStyle, 'bg-gray-200']
        textStyle = [...textStyle, 'text-gray-600']
    } else {
        constainerStyle = [...constainerStyle, 'bg-blue-600']
        textStyle = [...textStyle, 'text-white']
    }
    return (
        <div className="flex flex-row my-2">
            <div className={constainerStyle.join(' ')}>
                <p className={textStyle.join(' ')}>{props.message}</p>
            </div>
        </div>
    )
}

interface ITimestamp {
    time: string
}
function TimeStamp({ time }: ITimestamp): JSX.Element {
    return <p className="my-auto text-gray-400 text-sm">{time}</p>
}

export function MessageRow(props: { message: Message }) {

    const user = auth.currentUser
    let position:string;

    if (user !== null){
        if (props.message.sender_id === user.uid){
            position = 'right'
        } else {
            position = 'left'
        }
    } else {
        position = 'left'
    }

    return (
        <div>
            {position === 'left' ? (
                <div className="flex flex-row justify-start">
                    <TimeStamp time={timestampToStringHour(props.message.timestamp)} />
                    <MessageBulb message={props.message.text} position={position} />
                </div>
            ) : (
                <div className="flex flex-row justify-end">
                    <MessageBulb message={props.message.text} position={position} />
                    <TimeStamp time={timestampToStringHour(props.message.timestamp)} />
                </div>
            )}
        </div>
    )

}

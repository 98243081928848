import { collection, doc, setDoc } from 'firebase/firestore';
import react from 'react'
import { db } from '..';
import { auth } from '..';
import { nanoid } from 'nanoid'

interface prices {
    id: number,
    tokens: number,
    price: number,
    link: string,
    discount: number,
}

const prices = [
    {
        id: 1,
        tokens: 50,
        price: 5,
        link: 'https://buy.stripe.com/00g4it2ZY9Ns32UcMS?client_reference_id=',
        // link: 'https://buy.stripe.com/00gcOZcAy9NsgTK3cd?client_reference_id=',
        discount: 0,
    },
    {
        id: 2,
        tokens: 100,
        price: 10,
        link: 'https://buy.stripe.com/7sIg1basqe3I7ja8ww?client_reference_id=',
        discount: 0,
    },
    {
        id: 3,
        tokens: 250,
        price: 25,
        link: 'https://buy.stripe.com/8wM6qB1VU8Jo9rifZ0?client_reference_id=',
        discount: 0,
    },
    {
        id: 4,
        tokens: 520,
        price: 50,
        link: 'https://buy.stripe.com/fZe6qB0RQ7Fk8nebIL?client_reference_id=',
        discount: 5,
    },
    {
        id: 5,
        tokens: 1100,
        price: 100,
        link: 'https://buy.stripe.com/eVaaGR8ki7Fk5b23cg?client_reference_id=',
        discount: 10,
    },
    {
        id: 6,
        tokens: 3000,
        price: 250,
        link: 'https://buy.stripe.com/4gwdT38ki3p4eLC3ch?client_reference_id=',
        discount: 20,
    }
]

export function PurchaseCards() {

    
    return (
        <div className='flex flex-col lg:flex-row w-full sm:px-5 md:px-10 lg:px-20 py-10 justify-between lg:space-x-4'>

            {prices.map((data) => (
                <PurchaseCard key={data.id} data={data}/>
            ))}

        </div>
    )
}

function PurchaseCard(props: {data:prices}) {


    async function handlePurchase () {

        const user = auth.currentUser
        let docRef;
        if (user) {
            docRef = doc(db, 'payments', user.uid)
            const token = nanoid(32)
            await setDoc(docRef, {
                id: token,
                status: 'Waiting for payment',
                amount: props.data.tokens
            })

            const link = props.data.link + token

            window.location.replace(link);
        }

    }

    return (
        <div className="flex flex-row lg:flex-col flex-wrap justify-between sm:justify-center flex-grow w-full mx-auto bg-white rounded-xl p-4 h-fit shadow-lg mb-4">
            <div className='flex flex-col space-y-0 lg:space-y-1 mx-2'>
                <h1 className=' font-Poppins text-2xl font-bold w-full text-center'>{props.data.tokens}</h1>
                <p className=' font-Poppins text-sm text-gray-600'>Tokens</p>
            </div>
            <div className='mx-4 lg:mx-0 lg:my-4 flex flex-col justify-center'>
                <h1 className=' font-Poppins text-3xl lg:text-2xl font-light w-full text-center'>{props.data.price}€</h1>
            </div>
            <div className=' flex flex-col justify-center'>
                <div onClick={handlePurchase} className='bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-pink-500 hover:via-indigo-500 hover:to-purple-500 text-white font-bold p-[2px] mx-2 lg:mx-0 rounded-xxl'>
                    <div className="bg-white text-white font-bold py-2 px-4 rounded-xxl font-Poppins hover:transition hover:duration-300 hover:ease-in-out" >
                        <h3 className='font-Poppins text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 cursor-pointer' >Comprar</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCpbrcz4WyiYM7dr3oiU-xywsMhoaP70Y8",
  authDomain: "prueba-ai-6c71c.firebaseapp.com",
  databaseURL: "https://prueba-ai-6c71c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "prueba-ai-6c71c",
  storageBucket: "prueba-ai-6c71c.appspot.com",
  messagingSenderId: "800178254951",
  appId: "1:800178254951:web:b04410a17eef82a10910c2",
  measurementId: "G-FJ9B5LVDW6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app)
export const auth = getAuth(app)
export const rtdb = getDatabase(app)
export const storage = getStorage(app)
export const functions = getFunctions(app)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  
    <App />
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

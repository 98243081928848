import React from 'react'

interface IOption {
    children: JSX.Element
    style?: string
    onPress: () => void
}

export function Icon({ children, onPress, style }: IOption): JSX.Element {
    return (
        <div onClick={() => onPress()} className={'p-2 m-2 ' + style}>
            {children}
        </div>
    )
}

import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { CategorySelect } from './CategorySelect';
import { Icon } from './icon';
import { LocationSelect } from './locationSelect';

export function AnounceSearch(props: { onSearchButton: (search: string, location: string, category: number) => void }) {
  const [location, setLocation] = useState('');
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState(0)

  function handleCategory(category: number) {
    setCategory(category)
    console.log(category)
  }

  function handleLocation(location: string){
    setLocation(location)
  }

  return (
    <div className='flex flex-col space-y-2 md:space-y-0 md:flex-row h-fit md:h-16 justify-evenly w-full p-2 bg-gray-100 rounded-lg border-2 border-dashed border-gray-400'>
      <div className='flex flex-col justify-center'>
        <CategorySelect onHandleCategory={handleCategory} form={false} />
      </div>

      <LocationSelect onHandleLocation={handleLocation}/>
      <form onSubmit={(event) => {
        event.preventDefault()
        props.onSearchButton(search, location, category)
      }} className={'self-center'}>
        <div className='flex flex-row space-x-0 rounded-full h-fit border border-gray-300'>
          <div className="w-full h-full flex flex-col justify-center">
            <input
              type="text"
              id="search"
              className="rounded w-full bg-gray-100 rounded-l-full py-2 px-3 font-Poppins"
              value={search}
              placeholder="Búsqueda"
              onChange={(event) => {
                setSearch(event.target.value)
              }}
            />
          </div>
          <button
            type="submit"
            className="bg-gray-100 hover:bg-gray-200 text-gray-600 w-fit font-bold rounded-r-full p-2 self-center font-Poppins"

            onClick={() => props.onSearchButton(search, location, category)}
          >
            <div >
              <FiSearch className='h-6 w-6' />
            </div>
          </button>
        </div>

      </form >
    </div >
  );
};
